<template>
	<div :class="`${$screen.width <= 576 ? '.container-mobile' : fillClassContainer}`" class="clearfix px-0 pt-2 pb-4">
		<div class="form">
			<!-- <pre>{{ contract }}</pre> -->
			<!-- Work details -->
			<div class="card-collapsible px-0" v-if="$screen.width > 576">
				<div class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer" @click="handleClickHeader('work-details')">
					<div class="title w-90">
						<b-icon icon="file-earmark-person" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(129, 'Work details') }}</span>
					</div>
					<div class="cursor-pointer d-flex justify-content-end w-10">
						<component
							v-if="!configAccordion['work-details'].isVisible"
							:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
							:color="ICONS.CHEVRON_DOWN.color"
							:size="22"
						/>
						<component
							v-if="configAccordion['work-details'].isVisible"
							:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
							:color="ICONS.CHEVRON_UP.color"
							:size="22"
						/>
					</div>
				</div>
			</div>
			<b-collapse :visible="configAccordion['work-details'].isVisible">
				<div :class="`${$screen.width > 576 ? 'content-card-collapsible' : ''}`">
					<!-- Begin Work info -->
					<div class="card-collapsible px-0" v-if="$screen.width <= 576">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('work-info')"
						>
							<div class="title w-90">
								<b-icon icon="file-earmark-check" style="color: #06263e" font-scale="1.2" />
								<div class="fz-1-35 ml-3">{{ FormMSG(306, 'Work info') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['work-info'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['work-info'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['work-info'].isVisible">
						<div :class="`${$screen.width > 576 ? '' : 'content-card-collapsible inside'}`">
							<b-row>
								<b-col sm="12" md="12" :lg="`${showCCN2642CDDU() ? 4 : 6}`" :xl="`${showCCN2642CDDU() ? 4 : 6}`">
									<b-form-group :label="FormMSG(41, 'Union')">
										<b-form-select
											:disabled="isEditable() === false"
											v-model="contract.timeManagementType"
											:options="optionsForTimeManagementType"
											@change="handleManagementType"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6" v-if="showStandard()">
									<b-form-group :label="FormMSG(88, 'Default day type')">
										<b-form-select
											:disabled="isEditable() === false"
											v-model="contract.defaultDayType"
											:options="optionsForDefaultDayType"
										/>
										<!-- @change="getStandardPayCodeTitle" -->
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6" v-else-if="showCCNAnnex()">
									<b-form-group :label="FormMSG(42, 'Week type')" label-for="timeManagementType">
										<b-form-select :disabled="isEditable() === false" v-model="contract.workSixDays" :options="optionsForNumDays" />
										<!-- @change="computeSMG()" -->
									</b-form-group>
								</b-col>
								<div class="d-flex flex-row col-8 px-0" v-else-if="showCCN2642CDDU()">
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="FormMSG(324, 'Work Type - CCN 2642')">
											<b-form-select
												:disabled="isEditable() === false"
												v-model="contract.workSixDays"
												@change="handleCcn2642"
												:options="cnn2642Options"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="`${FormMSG(327, '35h week reference salary')}`" v-uni-for="'weeklyFiveDaysRefSalary'">
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.weeklyFiveDaysRefSalary"
													v-uni-id="'weeklyFiveDaysRefSalary'"
													type="text"
													placeholder="0"
													@update="handleWeeklyRefSalary"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</div>
								<b-col sm="12" md="12" lg="6" xl="6" v-else-if="showCCN2642CDICDD()">
									<b-form-group :label="`${FormMSG(328, 'Monthly reference salary')}`" v-uni-for="'cdiMonthlyReferenceSalary'">
										<b-input-group>
											<b-form-input
												:disabled="isEditable() === false"
												v-model="contract.cdiMonthlyReferenceSalary"
												v-uni-id="'cdiMonthlyReferenceSalary'"
												type="number"
												step="0.000001"
												min="0"
												placeholder="0"
												@update="handleMonthlyRefSalary"
											/>
											<b-input-group-append>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- Begin Work info -->
					<!-- Begin Main details -->
					<div class="card-collapsible px-0" :class="`${$screen.width > 576 ? 'mt-1' : 'mt-4'}`">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('main-details')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.LIST_CHECK.name)" :color="ICONS.LIST_CHECK.color" :size="22" />
								<div class="fz-1-35 ml-3">
									{{ FormMSG(130, 'Main details') }}
								</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['main-details'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['main-details'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['main-details'].isVisible">
						<div class="content-card-collapsible inside">
							<div v-if="showStandard()" class="px-0">
								<b-row>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 3}`"
										:xl="`${$screen.width <= 576 ? 0 : 3}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(89, 'Hourly rate') : FormMSG(13, 'Hourly rate')}`"
											v-uni-for="'hourlyRate'"
										>
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.hourlyRate"
													@change="computeSMG($event)"
													v-uni-id="'hourlyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													:class="{
														'is-invalid': isSubmitted && $v.contract.hourlyRate.$error
													}"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<div v-if="isSubmitted && $v.contract.hourlyRate.$error" class="invalid-feedback">
												{{ FormMSG(150, 'Please, value must be greater than 0') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 3}`"
										:xl="`${$screen.width <= 576 ? 0 : 3}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(11, 'Daily Rate') : FormMSG(90, 'Daily rate')}`"
											v-uni-for="'dailyRate'"
										>
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.dailyRate"
													v-uni-id="'dailyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													:class="{
														'is-invalid': isSubmitted && $v.contract.dailyRate.$error
													}"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<div v-if="isSubmitted && $v.contract.dailyRate.$error" class="invalid-feedback">
												{{ FormMSG(150, 'Please, value must be greater than 0') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 3}`"
										:xl="`${$screen.width <= 576 ? 0 : 3}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(310, 'Weekly rate') : FormMSG(123, 'Weekly rate')}`"
											v-uni-for="'weeklyRate'"
										>
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.weeklyFiveDaysMinimumSalary"
													v-uni-id="'weeklyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													@change="computeHourlyRate(contract.weeklyFiveDaysMinimumSalary)"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 3}`"
										:xl="`${$screen.width <= 576 ? 0 : 3}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(311, 'Monthly rate') : FormMSG(303, 'Monthly rate')}`"
											v-uni-for="'kmRate'"
										>
											<b-input-group>
												<b-form-input
													ref="kmRate"
													:disabled="isEditable() === false || contract.useMonthlyRate === false"
													v-model="contract.monthlyRate"
													v-uni-id="'monthlyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
											v-uni-for="'minDay'"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
												:uiid="'minDay'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerDayStr"
												:error="$v.contract.totMinPerDayStr.$error"
												:nbHour="rangeHour[WORKTYPE.daily]"
												:nbMinute="rangeMinute[WORKTYPE.daily]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerDay'"
												:workType="WORKTYPE.daily"
												@change="handleChangeHourMin"
											/>
											<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
												{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
											v-uni-for="'minWeek'"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
												:uiid="'minWeek'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerWeekStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerWeek'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group
											:label="`${
												$screen.width <= 576
													? FormMSG(312, 'Daily Work for continuous day')
													: FormMSG(295, 'Daily hours for continuous day')
											}`"
											v-uni-for="'minDayForCont'"
										>
											<input-date-time-picker
												:label="`${
													$screen.width <= 576
														? FormMSG(312, 'Daily Work for continuous day')
														: FormMSG(295, 'Daily hours for continuous day')
												}`"
												:uiid="'minDayForCont'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerDayForContDayStr"
												:error="$v.contract.totMinPerDayForContDayStr.$error"
												:nbHour="rangeHour[WORKTYPE.daily]"
												:nbMinute="rangeMinute[WORKTYPE.daily]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerDayForContDay'"
												:workType="WORKTYPE.daily"
												@change="handleChangeHourMin"
											/>
											<div v-if="isSubmitted && !$v.contract.totMinPerDayForContDayStr.hourMinuteValid" class="invalid-feedback">
												{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
											</div>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 6}`"
										:md="`${$screen.width <= 576 ? 0 : 6}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width > 576 ? FormMSG(167, 'Minimum hour guarantee') : FormMSG(307, 'Min hour guarantee')}`"
											v-uni-for="'minHourGuarantee'"
										>
											<input-date-time-picker
												:label="`${$screen.width > 576 ? FormMSG(167, 'Minimum hour guarantee') : FormMSG(307, 'Min hour guarantee')}`"
												:uiid="'minHourGuarantee'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.minimumHourGuaranteedStr"
												:nbHour="rangeHour[contract.minimumHourGuaranteedType === 0 ? WORKTYPE.daily : WORKTYPE.weekly]"
												:nbMinute="rangeMinute[contract.minimumHourGuaranteedType === 0 ? WORKTYPE.daily : WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'minimumHourGuaranteed'"
												:workType="contract.minimumHourGuaranteedType === 0 ? WORKTYPE.daily : WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 6}`"
										:md="`${$screen.width <= 576 ? 0 : 6}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<label for="minHourGuaranteeType">&nbsp;</label>
										<b-input-group>
											<b-form-select
												:disabled="isEditable() === false"
												v-model="contract.minimumHourGuaranteedType"
												:options="partialPeriodicalOptions"
												@change="handleGuaranteedType"
											/>
										</b-input-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group :label="`${FormMSG(12, 'Travel rate ')}`" v-uni-for="'travelDailyRate'">
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.travelDailyRate"
													v-uni-id="'travelDailyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row v-if="showDefaultPayCode">
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group :label="FormMSG(320, 'Pay code daily rate')">
											<payroll-code
												style-type="select"
												:ref="generateTextId('baseSalary1PayCode')"
												:value="contract.baseSalary1PayCode"
												@payroll-code:input="setPayroolCodeValue($event, 'baseSalary1PayCode')"
												@payroll-code:reset="resetPayroolCodeValue($event, 'baseSalary1PayCode')"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group :label="FormMSG(321, 'Pay code continuous day')">
											<payroll-code
												style-type="select"
												:ref="generateTextId('baseSalary2PayCode')"
												:value="contract.baseSalary2PayCode"
												@payroll-code:input="setPayroolCodeValue($event, 'baseSalary2PayCode')"
												@payroll-code:reset="resetPayroolCodeValue($event, 'baseSalary2PayCode')"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group :label="FormMSG(322, 'Pay code travel day')">
											<payroll-code
												style-type="select"
												:ref="generateTextId('baseSalary3PayCode')"
												:value="contract.baseSalary3PayCode"
												@payroll-code:input="setPayroolCodeValue($event, 'baseSalary3PayCode')"
												@payroll-code:reset="resetPayroolCodeValue($event, 'baseSalary3PayCode')"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group :label="FormMSG(323, 'Pay code hourly rate')">
											<payroll-code
												style-type="select"
												:ref="generateTextId('baseSalary4PayCode')"
												:value="contract.baseSalary4PayCode"
												@payroll-code:input="setPayroolCodeValue($event, 'baseSalary4PayCode')"
												@payroll-code:reset="resetPayroolCodeValue($event, 'baseSalary4PayCode')"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<div v-else-if="showCCNAnnex1()">
								<b-row>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(59, 'Hourly rate') : FormMSG(308, 'Hourly rate')}`"
											v-uni-for="'hrate'"
										>
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.hourlyRate"
													@change="computeSMG($event)"
													v-uni-id="'hrate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													:class="{
														'is-invalid': isSubmitted && $v.contract.hourlyRate.$error
													}"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<div v-if="isSubmitted && $v.contract.hourlyRate.$error" class="invalid-feedback">
												{{ FormMSG(150, 'Please, value must be greater than 0') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(143, 'Minimum salary (SMG)') : FormMSG(61, 'Minimum salary (SMG)')}`"
											v-uni-for="'smg'"
											v-if="contract.workSixDays === 0"
										>
											<b-input-group>
												<b-form-input
													disabled
													v-model="contract.weeklyBaseRefSalary"
													v-uni-id="'smg'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<!-- @change="computeHourlyRate(smgBase)" -->
										</b-form-group>
										<b-form-group
											:label="`${
												$screen.width <= 576
													? FormMSG(43, 'Five days minimum salary (SMG)')
													: FormMSG(62, '5 days minimum salary (SMG)')
											}`"
											v-uni-for="'smg'"
											v-if="contract.workSixDays === 1"
										>
											<b-input-group>
												<b-form-input
													disabled
													v-model="contract.weeklyFiveDaysMinimumSalary"
													v-uni-id="'smg'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
										<b-form-group
											:label="`${
												$screen.width <= 576
													? FormMSG(47, 'Six days minimum salary (SMG) €:')
													: FormMSG(65, '6 days minimum salary (SMG)')
											}`"
											v-uni-for="'sdsmg'"
											v-if="contract.workSixDays === 2"
										>
											<b-input-group>
												<b-form-input
													disabled
													v-model="contract.weeklySixDaysMinimumSalary"
													v-uni-id="'sdsmg'"
													type="number"
													step="0.000001"
													min="0"
													@change="computeHourlyRate(contract.weeklySixDaysMinimumSalary)"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group :label="`${FormMSG(12, 'Travel rate ')}`" v-uni-for="'travelDailyRate'">
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.travelDailyRate"
													v-uni-id="'travelDailyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" md="12" :lg="`${contract.workSixDays === 0 ? 6 : 4}`" :xl="`${contract.workSixDays === 0 ? 6 : 4}`">
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(104, 'Daily hours')}`"
											v-uni-for="'minDay'"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(104, 'Daily hours')}`"
												:uiid="'minDay'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerDayStr"
												:error="$v.contract.totMinPerDayStr.$error"
												:nbHour="rangeHour[WORKTYPE.daily]"
												:nbMinute="rangeMinute[WORKTYPE.daily]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerDay'"
												:workType="WORKTYPE.daily"
												@change="handleChangeHourMin"
											/>
											<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
												{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" :lg="`${contract.workSixDays === 0 ? 6 : 4}`" :xl="`${contract.workSixDays === 0 ? 6 : 4}`">
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(125, 'Weekly hours')}`"
											v-uni-for="'minWeek'"
											v-if="contract.workSixDays === 0"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(125, 'Weekly hours')}`"
												:uiid="'minWeek'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerWeekStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerWeek'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(63, '5 days effective hours')" v-uni-for="'fdeh'" v-if="contract.workSixDays === 1">
											<input-date-time-picker
												:label="FormMSG(63, '5 days effective hours')"
												:uiid="'fdeh'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklyFiveDaysEffNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklyFiveDaysEffNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(127, '6 days effective hours')" v-uni-for="'minWeek'" v-if="contract.workSixDays === 2">
											<input-date-time-picker
												:label="FormMSG(127, '6 days effective hours')"
												:uiid="'fdeh'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklySixDaysEffNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklySixDaysEffNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" v-if="contract.workSixDays > 0">
										<b-form-group :label="FormMSG(64, '5 days equivalent hours')" v-uni-for="'fdeq'" v-if="contract.workSixDays === 1">
											<input-date-time-picker
												:label="FormMSG(64, '5 days equivalent hours')"
												:uiid="'fdeq'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklyFiveDaysEquNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklyFiveDaysEquNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
										<b-form-group :label="FormMSG(128, '6 days equivalent hours')" v-uni-for="'fdeq'" v-if="contract.workSixDays === 2">
											<input-date-time-picker
												:label="FormMSG(128, '6 days equivalent hours')"
												:uiid="'fdeq'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklySixDaysEquNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklySixDaysEquNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<div v-else-if="showCCNAnnex3()">
								<b-row>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(68, 'Hourly rate') : FormMSG(309, 'Hourly rate')}`"
											v-uni-for="'hourlyRateA3'"
										>
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.hourlyRateAnnexThree"
													v-uni-id="'hourlyRateA3'"
													@change="computeSMG()"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													:class="{
														'is-invalid': isSubmitted && $v.contract.hourlyRateAnnexThree.$error
													}"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<div v-if="isSubmitted && $v.contract.hourlyRateAnnexThree.$error" class="invalid-feedback">
												{{ FormMSG(150, 'Please, value must be greater than 0') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="`${FormMSG(12, 'Travel rate ')}`" v-uni-for="'travelDailyRate'">
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.travelDailyRate"
													v-uni-id="'travelDailyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group :label="FormMSG(69, 'Weekly bonus')" v-uni-for="'weeklyBonusAmount'" v-if="contract.workSixDays === 0">
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.weeklyBonusAmount"
													v-uni-id="'weeklyBonusAmount'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
										<b-form-group
											:label="FormMSG(72, 'Weekly bonus')"
											v-uni-for="'weeklyBonusAmountFiveDays'"
											v-if="contract.workSixDays === 1"
										>
											<b-input-group>
												<b-form-input
													v-model="contract.weeklyBonusAmountFiveDays"
													v-uni-id="'weeklyBonusAmountFiveDays'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
										<b-form-group
											:label="FormMSG(77, 'Weekly bonus')"
											v-uni-for="'weeklyBonusAmountSixDays'"
											v-if="contract.workSixDays === 2"
										>
											<b-input-group>
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.weeklyBonusAmountSixDays"
													v-uni-id="'weeklyBonusAmountSixDays'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group :label="FormMSG(70, 'Min. salary')" v-uni-for="'wbms'" v-if="contract.workSixDays === 0">
											<b-input-group>
												<b-form-input
													:disabled="true"
													v-model="contract.weeklyBaseMinimumSalary"
													v-uni-id="'wbms'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
										<b-form-group :label="FormMSG(73, '5 days min. salary')" v-uni-for="'wbms'" v-if="contract.workSixDays === 1">
											<b-input-group>
												<b-form-input
													:disabled="true"
													v-model="contract.weeklyFiveDaysMinimumSalaryAnThree"
													v-uni-id="'wbms'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
										<b-form-group :label="FormMSG(78, '6 days min. salary')" v-uni-for="'wbms'" v-if="contract.workSixDays === 2">
											<b-input-group>
												<b-form-input
													:disabled="true"
													v-model="contract.weeklySixDaysMinimumSalaryAnThree"
													v-uni-id="'wbms'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4">
										<b-form-group :label="FormMSG(71, 'Ref. salary')" v-uni-for="'wbrf'" v-if="contract.workSixDays === 0">
											<b-input-group>
												<b-form-input
													:disabled="true"
													v-model="contract.weeklyBaseRefSalary"
													v-uni-id="'wbrf'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
										<b-form-group :label="FormMSG(76, '5 days ref. salary')" v-uni-for="'wbrf'" v-if="contract.workSixDays === 1">
											<b-input-group>
												<b-form-input
													:disabled="true"
													v-model="contract.weeklyFiveDaysRefSalary"
													v-uni-id="'wbrf'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
										<b-form-group :label="FormMSG(81, '6 days ref. salary')" v-uni-for="'wbrf'" v-if="contract.workSixDays === 2">
											<b-input-group>
												<b-form-input
													:disabled="true"
													v-model="contract.weeklySixDaysRefSalary"
													v-uni-id="'wbrf'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" md="12" :lg="`${contract.workSixDays === 0 ? 6 : 4}`" :xl="`${contract.workSixDays === 0 ? 6 : 4}`">
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(104, 'Daily hours')}`"
											v-uni-for="'minDay'"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(104, 'Daily hours')}`"
												:uiid="'minDay'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerDayStr"
												:error="$v.contract.totMinPerDayStr.$error"
												:nbHour="rangeHour[WORKTYPE.daily]"
												:nbMinute="rangeMinute[WORKTYPE.daily]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerDay'"
												:workType="WORKTYPE.daily"
												@change="handleChangeHourMin"
											/>
											<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
												{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										sm="12"
										md="12"
										:lg="`${contract.workSixDays === 0 ? 6 : 4}`"
										:xl="`${contract.workSixDays === 0 ? 6 : 4}`"
										v-if="contract.workSixDays === 0"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
											v-uni-for="'minWeek'"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
												:uiid="'minWeek'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerWeekStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'totMinPerWeek'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
											<!-- <div
												v-if="isSubmitted && !$v.contract.totMinPerWeekStr.hourMinuteValid"
												class="invalid-feedback"
											>
												{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
											</div> -->
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" v-if="contract.workSixDays === 1">
										<b-form-group :label="FormMSG(74, '5 days effective hours')" v-uni-for="'fdeh'">
											<input-date-time-picker
												:label="FormMSG(74, '5 days effective hours')"
												:uiid="'fdeh'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklyFiveDaysEffNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklyFiveDaysEffNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" v-if="contract.workSixDays === 2">
										<b-form-group :label="FormMSG(79, '6 days effective hours')" v-uni-for="'fdeh'">
											<input-date-time-picker
												:label="FormMSG(79, '6 days effective hours')"
												:uiid="'fdeh'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklySixDaysEffNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklySixDaysEffNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" v-if="contract.workSixDays === 1">
										<b-form-group :label="this.FormMSG(75, '5 days equivalent hours')" v-uni-for="'fdeq'">
											<input-date-time-picker
												:label="this.FormMSG(75, '5 days equivalent hours')"
												:uiid="'fdeq'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklyFiveDaysEquNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklyFiveDaysEquNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" v-if="contract.workSixDays === 2">
										<b-form-group :label="this.FormMSG(80, '6 days equivalent hours')" v-uni-for="'fdeq'">
											<input-date-time-picker
												:label="this.FormMSG(80, '6 days equivalent hours')"
												:uiid="'fdeq'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.weeklySixDaysEquNumHoursStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="() => isEditable()"
												:fieldName="'weeklySixDaysEquNumHours'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<div v-else-if="showCCN2642CDICDD()">
								<b-row>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(89, 'Hourly rate') : FormMSG(13, 'Hourly rate')}`"
											v-uni-for="'hourlyRate'"
										>
											<b-input-group>
												<b-form-input
													disabled
													v-model="contract.hourlyRate"
													@change="computeSMG($event)"
													v-uni-id="'hourlyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													:class="{
														'is-invalid': isSubmitted && $v.contract.hourlyRate.$error
													}"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<div v-if="isSubmitted && $v.contract.hourlyRate.$error" class="invalid-feedback">
												{{ FormMSG(150, 'Please, value must be greater than 0') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(11, 'Daily Rate') : FormMSG(90, 'Daily rate')}`"
											v-uni-for="'dailyRate'"
										>
											<b-input-group>
												<b-form-input
													disabled
													v-model="contract.dailyRate"
													v-uni-id="'dailyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													:class="{
														'is-invalid': isSubmitted && $v.contract.dailyRate.$error
													}"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
											<div v-if="isSubmitted && $v.contract.dailyRate.$error" class="invalid-feedback">
												{{ FormMSG(150, 'Please, value must be greater than 0') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 4}`"
										:xl="`${$screen.width <= 576 ? 0 : 4}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(311, 'Monthly rate') : FormMSG(303, 'Monthly rate')}`"
											v-uni-for="'kmRate'"
										>
											<b-input-group>
												<b-form-input
													ref="kmRate"
													disabled
													v-model="contract.monthlyRate"
													v-uni-id="'monthlyRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 6}`"
										:xl="`${$screen.width <= 576 ? 0 : 6}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
											v-uni-for="'minDay'"
										>
											<input-date-time-picker
												disabled
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
												:uiid="'minDay'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerDayStr"
												:error="$v.contract.totMinPerDayStr.$error"
												:nbHour="rangeHour[WORKTYPE.daily]"
												:nbMinute="rangeMinute[WORKTYPE.daily]"
												:isEditable="
													() => {
														return false;
													}
												"
												:fieldName="'totMinPerDay'"
												:workType="WORKTYPE.daily"
												@change="handleChangeHourMin"
											/>
											<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
												{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col
										:sm="`${$screen.width <= 576 ? 0 : 12}`"
										:md="`${$screen.width <= 576 ? 0 : 12}`"
										:lg="`${$screen.width <= 576 ? 0 : 6}`"
										:xl="`${$screen.width <= 576 ? 0 : 6}`"
										:cols="`${$screen.width <= 576 ? 6 : 0}`"
									>
										<b-form-group
											:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
											v-uni-for="'minWeek'"
										>
											<input-date-time-picker
												:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
												:uiid="'minWeek'"
												:type="'text'"
												:placeholder="'hh:mm'"
												:isSubmitted="isSubmitted"
												:item="contract.totMinPerWeekStr"
												:nbHour="rangeHour[WORKTYPE.weekly]"
												:nbMinute="rangeMinute[WORKTYPE.weekly]"
												:isEditable="
													() => {
														return false;
													}
												"
												:fieldName="'totMinPerWeek'"
												:workType="WORKTYPE.weekly"
												@change="handleChangeHourMin"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<div v-else-if="showCCN2642CDDU()">
								<div v-if="showCNN2624DailyBase()">
									<b-row>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(89, 'Hourly rate') : FormMSG(13, 'Hourly rate')}`"
												v-uni-for="'hourlyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.hourlyRate"
														@change="computeSMG($event)"
														v-uni-id="'hourlyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
														:class="{
															'is-invalid': isSubmitted && $v.contract.hourlyRate.$error
														}"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<div v-if="isSubmitted && $v.contract.hourlyRate.$error" class="invalid-feedback">
													{{ FormMSG(150, 'Please, value must be greater than 0') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(11, 'Daily Rate') : FormMSG(90, 'Daily rate')}`"
												v-uni-for="'dailyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.dailyRate"
														v-uni-id="'dailyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
														:class="{
															'is-invalid': isSubmitted && $v.contract.dailyRate.$error
														}"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<div v-if="isSubmitted && $v.contract.dailyRate.$error" class="invalid-feedback">
													{{ FormMSG(150, 'Please, value must be greater than 0') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
												v-uni-for="'minDay'"
											>
												<input-date-time-picker
													:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
													:uiid="'minDay'"
													:type="'text'"
													:placeholder="'hh:mm'"
													:isSubmitted="isSubmitted"
													:item="contract.totMinPerDayStr"
													:error="$v.contract.totMinPerDayStr.$error"
													:nbHour="rangeHour[WORKTYPE.daily]"
													:nbMinute="rangeMinute[WORKTYPE.daily]"
													:isEditable="
														() => {
															return false;
														}
													"
													:fieldName="'totMinPerDay'"
													:workType="WORKTYPE.daily"
													@change="handleChangeHourMin"
												/>
												<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
													{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
												</div>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
								<div v-if="showCNN2624WeeklyBase()">
									<b-row>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(89, 'Hourly rate') : FormMSG(13, 'Hourly rate')}`"
												v-uni-for="'hourlyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.hourlyRate"
														@change="computeSMG($event)"
														v-uni-id="'hourlyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
														:class="{
															'is-invalid': isSubmitted && $v.contract.hourlyRate.$error
														}"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<div v-if="isSubmitted && $v.contract.hourlyRate.$error" class="invalid-feedback">
													{{ FormMSG(150, 'Please, value must be greater than 0') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(11, 'Daily Rate') : FormMSG(90, 'Daily rate')}`"
												v-uni-for="'dailyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.dailyRate"
														v-uni-id="'dailyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
														:class="{
															'is-invalid': isSubmitted && $v.contract.dailyRate.$error
														}"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<div v-if="isSubmitted && $v.contract.dailyRate.$error" class="invalid-feedback">
													{{ FormMSG(150, 'Please, value must be greater than 0') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
												v-uni-for="'minDay'"
											>
												<input-date-time-picker
													:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
													:uiid="'minDay'"
													:type="'text'"
													:placeholder="'hh:mm'"
													:isSubmitted="isSubmitted"
													:item="contract.totMinPerDayStr"
													:error="$v.contract.totMinPerDayStr.$error"
													:nbHour="rangeHour[WORKTYPE.daily]"
													:nbMinute="rangeMinute[WORKTYPE.daily]"
													:isEditable="
														() => {
															return false;
														}
													"
													:fieldName="'totMinPerDay'"
													:workType="WORKTYPE.daily"
													@change="handleChangeHourMin"
												/>
												<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
													{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
												</div>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 6}`"
											:xl="`${$screen.width <= 576 ? 0 : 6}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(326, 'Tarif semaine (info)') : FormMSG(325, 'Tarif semaine (info)')}`"
												v-uni-for="'weeklyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.weeklyFiveDaysMinimumSalary"
														v-uni-id="'weeklyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 6}`"
											:xl="`${$screen.width <= 576 ? 0 : 6}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
												v-uni-for="'minWeek'"
											>
												<input-date-time-picker
													:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
													:uiid="'minWeek'"
													:type="'text'"
													:placeholder="'hh:mm'"
													:isSubmitted="isSubmitted"
													:item="contract.totMinPerWeekStr"
													:nbHour="rangeHour[WORKTYPE.weekly]"
													:nbMinute="rangeMinute[WORKTYPE.weekly]"
													:isEditable="
														() => {
															return false;
														}
													"
													:fieldName="'totMinPerWeek'"
													:workType="WORKTYPE.weekly"
													@change="handleChangeHourMin"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
								<div v-if="showCNN2624MonthlyBase()">
									<b-row>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(89, 'Hourly rate') : FormMSG(13, 'Hourly rate')}`"
												v-uni-for="'hourlyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.hourlyRate"
														@change="computeSMG($event)"
														v-uni-id="'hourlyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
														:class="{
															'is-invalid': isSubmitted && $v.contract.hourlyRate.$error
														}"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<div v-if="isSubmitted && $v.contract.hourlyRate.$error" class="invalid-feedback">
													{{ FormMSG(150, 'Please, value must be greater than 0') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(11, 'Daily Rate') : FormMSG(90, 'Daily rate')}`"
												v-uni-for="'dailyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.dailyRate"
														v-uni-id="'dailyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
														:class="{
															'is-invalid': isSubmitted && $v.contract.dailyRate.$error
														}"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<div v-if="isSubmitted && $v.contract.dailyRate.$error" class="invalid-feedback">
													{{ FormMSG(150, 'Please, value must be greater than 0') }}
												</div>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
												v-uni-for="'minDay'"
											>
												<input-date-time-picker
													:label="`${$screen.width <= 576 ? FormMSG(24, 'Daily Work') : FormMSG(92, 'Daily hours')}`"
													:uiid="'minDay'"
													:type="'text'"
													:placeholder="'hh:mm'"
													:isSubmitted="isSubmitted"
													:item="contract.totMinPerDayStr"
													:error="$v.contract.totMinPerDayStr.$error"
													:nbHour="rangeHour[WORKTYPE.daily]"
													:nbMinute="rangeMinute[WORKTYPE.daily]"
													:isEditable="
														() => {
															return false;
														}
													"
													:fieldName="'totMinPerDay'"
													:workType="WORKTYPE.daily"
													@change="handleChangeHourMin"
												/>
												<div v-if="isSubmitted && !$v.contract.totMinPerDayStr.hourMinuteValid" class="invalid-feedback">
													{{ FormMSG(151, 'Please, enter hours and minutes valid. (e.g: 45:00)') }}
												</div>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(326, 'Tarif semaine (info)') : FormMSG(325, 'Tarif semaine (info)')}`"
												v-uni-for="'weeklyRate'"
											>
												<b-input-group>
													<b-form-input
														disabled
														v-model="contract.weeklyFiveDaysMinimumSalary"
														v-uni-id="'weeklyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
												v-uni-for="'minWeek'"
											>
												<input-date-time-picker
													:label="`${$screen.width <= 576 ? FormMSG(25, 'Weekly Work') : FormMSG(91, 'Weekly hours')}`"
													:uiid="'minWeek'"
													:type="'text'"
													:placeholder="'hh:mm'"
													:isSubmitted="isSubmitted"
													:item="contract.totMinPerWeekStr"
													:nbHour="rangeHour[WORKTYPE.weekly]"
													:nbMinute="rangeMinute[WORKTYPE.weekly]"
													:isEditable="
														() => {
															return false;
														}
													"
													:fieldName="'totMinPerWeek'"
													:workType="WORKTYPE.weekly"
													@change="handleChangeHourMin"
												/>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 12}`"
											:md="`${$screen.width <= 576 ? 0 : 12}`"
											:lg="`${$screen.width <= 576 ? 0 : 4}`"
											:xl="`${$screen.width <= 576 ? 0 : 4}`"
											:cols="`${$screen.width <= 576 ? 6 : 0}`"
										>
											<b-form-group
												:label="`${$screen.width <= 576 ? FormMSG(311, 'Monthly rate') : FormMSG(303, 'Monthly rate')}`"
												v-uni-for="'kmRate'"
											>
												<b-input-group>
													<b-form-input
														ref="kmRate"
														disabled
														v-model="contract.monthlyRate"
														v-uni-id="'monthlyRate'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
					</b-collapse>
					<!-- End Main details -->
					<!-- Begin Overtimes -->
					<div class="card-collapsible px-0 mt-4" v-if="showOvt || showOvt1 || showOvt2 || showWeeklyOvt || showWeeklyOvt1 || showWeeklyOvt2">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('overtimes')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.ALARM_PLUS.name)" :color="ICONS.ALARM_PLUS.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(131, 'Overtimes') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['overtimes'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['overtimes'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse
						:visible="configAccordion['overtimes'].isVisible"
						v-if="showOvt || showOvt1 || showOvt2 || showWeeklyOvt || showWeeklyOvt1 || showWeeklyOvt2"
					>
						<div class="content-card-collapsible inside">
							<div v-if="showStandard()">
								<div class="label-layout pb-3" v-if="showOvt || showOvt1 || showOvt2">
									<b-form-checkbox
										size="lg"
										class="pj-cb-plus-2 cursor-pointer"
										v-model="contract.dailyOvertime"
										:disabled="isEditable() === false"
									>
										{{ FormMSG(137, 'Daily overtimes') }}
									</b-form-checkbox>
								</div>
								<div v-if="showOvt || showOvt1 || showOvt2">
									<b-row v-if="contract.dailyOvertime">
										<b-col
											sm="12"
											md="12"
											:lg="`${showOvt1 && showOvt2 ? 4 : !showOvt1 && !showOvt2 ? 12 : 6}`"
											:xl="`${showOvt1 && showOvt2 ? 4 : !showOvt1 && !showOvt2 ? 12 : 6}`"
											v-if="showOvt"
										>
											<fieldset class="card-inside">
												<legend class="card-inside">
													{{ FormMSG(132, 'Daily overtime 1') }}
												</legend>
												<b-row :class="`${showDefaultPayCode ? '' : 'd-flex align-items-center'}`" v-if="$screen.width > 576">
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-row>
															<b-col sm="12" md="12" lg="12" xl="12">
																<b-form-group :label="FormMSG(298, 'After')" v-uni-for="'ovtLimit1'">
																	<input-date-time-picker
																		:label="FormMSG(298, 'After')"
																		v-uni-for="'ovtLimit1'"
																		:uiid="'ovtLimit1'"
																		:type="'text'"
																		:placeholder="'hh:mm'"
																		:isSubmitted="isSubmitted"
																		:item="contract.ovtLimitStr"
																		:nbHour="rangeHour[WORKTYPE.daily]"
																		:nbMinute="rangeMinute[WORKTYPE.daily]"
																		:isEditable="() => isEditable()"
																		:fieldName="'ovtLimit'"
																		:workType="WORKTYPE.daily"
																		@change="handleChangeHourMin"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="12" md="12" lg="12" xl="12">
																<b-form-group
																	:label="`${
																		$screen.width <= 576
																			? FormMSG(302, 'After (cont. day)')
																			: FormMSG(299, 'After (continuous day)')
																	}`"
																	v-uni-for="'ovtLimitContinuousDay'"
																>
																	<input-date-time-picker
																		:label="`${
																			$screen.width <= 576
																				? FormMSG(302, 'After (cont. day)')
																				: FormMSG(299, 'After (continuous day)')
																		}`"
																		:uiid="'ovtLimitContinuousDay'"
																		:type="'text'"
																		:placeholder="'hh:mm'"
																		:isSubmitted="isSubmitted"
																		:item="contract.ovtLimitContinuousDayStr"
																		:nbHour="rangeHour[WORKTYPE.daily]"
																		:nbMinute="rangeMinute[WORKTYPE.daily]"
																		:isEditable="() => isEditable()"
																		:fieldName="'ovtLimitContinuousDay'"
																		:workType="WORKTYPE.daily"
																		@change="handleChangeHourMin"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
													</b-col>
													<b-col sm="12" md="12" lg="6" xl="6">
														<b-form-group :label="FormMSG(95, 'Overtime rate')" v-uni-for="'overtimeRate1'">
															<b-input-group>
																<b-form-input
																	:disabled="isEditable() === false"
																	v-model="contract.overtimeRate"
																	v-uni-id="'overtimeRate1'"
																	type="number"
																	step="0.000001"
																	min="0"
																	placeholder="0"
																/>
																<b-input-group-append>
																	<b-input-group-text>
																		<component
																			:is="getLucideIcon(ICONS.PERCENT.name)"
																			:color="ICONS.PERCENT.color"
																			:size="16"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
														<b-form-group v-if="showDefaultPayCode" :style="`margin:${$screen.width <= 992 ? '0' : '6px 0 0 0'}`">
															<label for="dayOvt1PayCode" v-if="$screen.width > 992">&nbsp;</label>
															<payroll-code
																style-type="select"
																:ref="generateTextId('dayOvt1PayCode')"
																:value="contract.dayOvt1PayCode"
																@payroll-code:input="setPayroolCodeValue($event, 'dayOvt1PayCode')"
																@payroll-code:reset="resetPayroolCodeValue($event, 'dayOvt1PayCode')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row v-else>
													<b-col cols="6">
														<b-form-group :label="FormMSG(301, 'After')" v-uni-for="'ovtLimit1'">
															<input-date-time-picker
																:label="FormMSG(301, 'After')"
																:uiid="'ovtLimit1'"
																:type="'text'"
																:placeholder="'hh:mm'"
																:isSubmitted="isSubmitted"
																:item="contract.ovtLimitStr"
																:nbHour="rangeHour[WORKTYPE.daily]"
																:nbMinute="rangeMinute[WORKTYPE.daily]"
																:isEditable="() => isEditable()"
																:fieldName="'ovtLimit'"
																:workType="WORKTYPE.daily"
																@change="handleChangeHourMin"
															/>
														</b-form-group>
													</b-col>
													<b-col cols="6">
														<b-form-group :label="FormMSG(302, 'After (cont. day)')" v-uni-for="'ovtLimitContinuousDay'">
															<input-date-time-picker
																:label="FormMSG(302, 'After (cont. day)')"
																:uiid="'ovtLimitContinuousDay'"
																:type="'text'"
																:placeholder="'hh:mm'"
																:isSubmitted="isSubmitted"
																:item="contract.ovtLimitContinuousDayStr"
																:nbHour="rangeHour[WORKTYPE.daily]"
																:nbMinute="rangeMinute[WORKTYPE.daily]"
																:isEditable="() => isEditable()"
																:fieldName="'ovtLimitContinuousDay'"
																:workType="WORKTYPE.daily"
																@change="handleChangeHourMin"
															/>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group :label="FormMSG(14, 'Daily overtime rate')" v-uni-for="'overtimeRate1'">
															<b-input-group>
																<b-form-input
																	:disabled="isEditable() === false"
																	v-model="contract.overtimeRate"
																	v-uni-id="'overtimeRate1'"
																	type="number"
																	step="0.000001"
																	min="0"
																	placeholder="0"
																/>
																<b-input-group-append>
																	<b-input-group-text>
																		<component
																			:is="getLucideIcon(ICONS.PERCENT.name)"
																			:color="ICONS.PERCENT.color"
																			:size="16"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
													<b-col cols="12" v-if="showDefaultPayCode">
														<b-form-group>
															<!-- <label for="dayOvt1PayCode">&nbsp;</label> -->
															<payroll-code
																style-type="select"
																:ref="generateTextId('dayOvt1PayCode')"
																:value="contract.dayOvt1PayCode"
																@payroll-code:input="setPayroolCodeValue($event, 'dayOvt1PayCode')"
																@payroll-code:reset="resetPayroolCodeValue($event, 'dayOvt1PayCode')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</fieldset>
										</b-col>
										<b-col
											sm="12"
											md="12"
											:lg="`${showOvt && showOvt2 ? 4 : !showOvt && !showOvt2 ? 12 : 6}`"
											:xl="`${showOvt && showOvt2 ? 4 : !showOvt && !showOvt2 ? 12 : 6}`"
											v-if="showOvt1"
										>
											<fieldset class="card-inside">
												<legend class="card-inside">
													{{ FormMSG(133, 'Daily overtime 2') }}
												</legend>
												<b-row>
													<b-col cols="6">
														<b-form-group :label="FormMSG(96, 'Overtime after')" v-uni-for="'ovtLimit2'">
															<input-date-time-picker
																:label="FormMSG(96, 'Overtime after')"
																:uiid="'ovtLimit2'"
																:type="'text'"
																:placeholder="'hh:mm'"
																:isSubmitted="isSubmitted"
																:item="contract.ovtLimit1Str"
																:nbHour="rangeHour[WORKTYPE.daily]"
																:nbMinute="rangeMinute[WORKTYPE.daily]"
																:isEditable="() => isEditable()"
																:fieldName="'ovtLimit1'"
																:workType="WORKTYPE.daily"
																@change="handleChangeHourMin"
															/>
														</b-form-group>
													</b-col>
													<b-col cols="6">
														<b-form-group :label="FormMSG(97, 'Overtime rate')" v-uni-for="'overtimeRate2'">
															<b-input-group>
																<b-form-input
																	:disabled="isEditable() === false"
																	v-model="contract.ovtRate1"
																	v-uni-id="'overtimeRate2'"
																	type="number"
																	step="0.000001"
																	min="0"
																	placeholder="0"
																/>
																<b-input-group-append>
																	<b-input-group-text>
																		<component
																			:is="getLucideIcon(ICONS.PERCENT.name)"
																			:color="ICONS.PERCENT.color"
																			:size="16"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row v-if="showDefaultPayCode">
													<b-col cols="12">
														<b-form-group>
															<label for="dayOvt2PayCode" v-if="$screen.width > 992">&nbsp;</label>
															<payroll-code
																style-type="select"
																:ref="generateTextId('dayOvt2PayCode')"
																:value="contract.dayOvt2PayCode"
																@payroll-code:input="setPayroolCodeValue($event, 'dayOvt2PayCode')"
																@payroll-code:reset="resetPayroolCodeValue($event, 'dayOvt2PayCode')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</fieldset>
										</b-col>
										<b-col
											sm="12"
											md="12"
											:lg="`${showOvt1 && showOvt ? 4 : !showOvt1 && !showOvt ? 12 : 6}`"
											:xl="`${showOvt1 && showOvt ? 4 : !showOvt1 && !showOvt ? 12 : 6}`"
											v-if="showOvt2"
										>
											<fieldset class="card-inside">
												<legend class="card-inside">
													{{ FormMSG(134, 'Daily overtime 3') }}
												</legend>
												<b-row>
													<b-col cols="6">
														<b-form-group :label="FormMSG(135, 'Overtime after')" v-uni-for="'ovtLimit3'">
															<input-date-time-picker
																:label="FormMSG(135, 'Overtime after')"
																:uiid="'ovtLimit3'"
																:type="'text'"
																:placeholder="'hh:mm'"
																:isSubmitted="isSubmitted"
																:item="contract.ovtLimit2Str"
																:nbHour="rangeHour[WORKTYPE.daily]"
																:nbMinute="rangeMinute[WORKTYPE.daily]"
																:isEditable="() => isEditable()"
																:fieldName="'ovtLimit2'"
																:workType="WORKTYPE.daily"
																@change="handleChangeHourMin"
															/>
														</b-form-group>
													</b-col>
													<b-col cols="6">
														<b-form-group :label="FormMSG(136, 'Overtime rate')" v-uni-for="'overtimeRate3'">
															<b-input-group>
																<b-form-input
																	:disabled="isEditable() === false"
																	v-model="contract.ovtRate2"
																	v-uni-id="'overtimeRate3'"
																	type="number"
																	step="0.000001"
																	min="0"
																	placeholder="0"
																/>
																<b-input-group-append>
																	<b-input-group-text>
																		<component
																			:is="getLucideIcon(ICONS.PERCENT.name)"
																			:color="ICONS.PERCENT.color"
																			:size="16"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row v-if="showDefaultPayCode">
													<b-col cols="12">
														<b-form-group>
															<label for="dayOvt3PayCode" v-if="$screen.width > 992">&nbsp;</label>
															<payroll-code
																style-type="select"
																:ref="generateTextId('dayOvt3PayCode')"
																:value="contract.dayOvt3PayCode"
																@payroll-code:input="setPayroolCodeValue($event, 'dayOvt3PayCode')"
																@payroll-code:reset="resetPayroolCodeValue($event, 'dayOvt3PayCode')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</fieldset>
										</b-col>
									</b-row>
								</div>
							</div>
							<div class="divider" v-if="(showOvt || showOvt1 || showOvt2) && showStandard()" />
							<div
								class="label-layout pb-3"
								:class="`${
									(showOvt || showOvt1 || showOvt2) && (showWeeklyOvt || showWeeklyOvt1 || showWeeklyOvt2) && !showStandard() ? 'mt-4' : ''
								}`"
								v-if="showWeeklyOvt || showWeeklyOvt1 || showWeeklyOvt2"
							>
								<b-form-checkbox
									size="lg"
									class="pj-cb-plus-2 cursor-pointer"
									v-model="contract.weeklyOvertime"
									:disabled="isEditable() === false"
								>
									{{ FormMSG(138, 'Weekly overtimes') }}
								</b-form-checkbox>
							</div>
							<div v-if="showWeeklyOvt || showWeeklyOvt1 || showWeeklyOvt2">
								<b-row v-if="contract.weeklyOvertime">
									<b-col
										sm="12"
										md="12"
										:lg="`${showWeeklyOvt1 && showWeeklyOvt2 ? 4 : !showWeeklyOvt1 && !showWeeklyOvt2 ? 12 : 6}`"
										:xl="`${showWeeklyOvt1 && showWeeklyOvt2 ? 4 : !showWeeklyOvt1 && !showWeeklyOvt2 ? 12 : 6}`"
										v-if="showWeeklyOvt"
									>
										<fieldset class="card-inside">
											<legend class="card-inside">
												{{ FormMSG(139, 'Weekly overtime 1') }}
											</legend>
											<b-row>
												<b-col cols="6">
													<b-form-group :label="FormMSG(152, 'Overtime after')" v-uni-for="'weeklyOvtLimit1'">
														<input-date-time-picker
															:label="FormMSG(152, 'Overtime after')"
															:uiid="'weeklyOvtLimit1'"
															:type="'text'"
															:placeholder="'hh:mm'"
															:isSubmitted="isSubmitted"
															:item="contract.weeklyOvtOneLimitStr"
															:nbHour="rangeHour[WORKTYPE.weekly]"
															:nbMinute="rangeMinute[WORKTYPE.weekly]"
															:isEditable="() => isEditable()"
															:fieldName="'weeklyOvtOneLimit'"
															:workType="WORKTYPE.weekly"
															@change="handleChangeHourMin"
														/>
													</b-form-group>
												</b-col>
												<b-col cols="6">
													<b-form-group :label="FormMSG(153, 'Overtime rate')" v-uni-for="'weeklyOvertimeRate1'">
														<b-input-group>
															<b-form-input
																:disabled="isEditable() === false"
																v-model="contract.weeklyOvtOneRate"
																v-uni-id="'weeklyOvertimeRate1'"
																type="number"
																step="0.000001"
																min="0"
																@change="computeSMG()"
																placeholder="0"
															/>
															<b-input-group-append>
																<b-input-group-text>
																	<component
																		:is="getLucideIcon(ICONS.PERCENT.name)"
																		:color="ICONS.PERCENT.color"
																		:size="16"
																	/>
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row v-if="showDefaultPayCode">
												<b-col cols="12">
													<b-form-group>
														<label for="weekOvt1PayCode" v-if="$screen.width > 992">&nbsp;</label>
														<payroll-code
															style-type="select"
															:ref="generateTextId('weekOvt1PayCode')"
															:value="contract.weekOvt1PayCode"
															@payroll-code:input="setPayroolCodeValue($event, 'weekOvt1PayCode')"
															@payroll-code:reset="resetPayroolCodeValue($event, 'weekOvt1PayCode')"
														/>
													</b-form-group>
												</b-col>
											</b-row>
										</fieldset>
									</b-col>
									<b-col
										sm="12"
										md="12"
										:lg="`${showWeeklyOvt && showWeeklyOvt2 ? 4 : !showWeeklyOvt && !showWeeklyOvt2 ? 12 : 6}`"
										:xl="`${showWeeklyOvt && showWeeklyOvt2 ? 4 : !showWeeklyOvt && !showWeeklyOvt2 ? 12 : 6}`"
										v-if="showWeeklyOvt1"
									>
										<fieldset class="card-inside">
											<legend class="card-inside">
												{{ FormMSG(161, 'Weekly overtime 2') }}
											</legend>
											<b-row>
												<b-col cols="6">
													<b-form-group :label="FormMSG(156, 'Overtime after')" v-uni-for="'weeklyOvtLimit2'">
														<input-date-time-picker
															:label="FormMSG(156, 'Overtime after')"
															:uiid="'weeklyOvtLimit2'"
															:type="'text'"
															:placeholder="'hh:mm'"
															:isSubmitted="isSubmitted"
															:item="contract.weeklyOvtTwoLimitStr"
															:nbHour="rangeHour[WORKTYPE.weekly]"
															:nbMinute="rangeMinute[WORKTYPE.weekly]"
															:isEditable="() => isEditable()"
															:fieldName="'weeklyOvtTwoLimit'"
															:workType="WORKTYPE.weekly"
															@change="handleChangeHourMin"
														/>
													</b-form-group>
												</b-col>
												<b-col cols="6">
													<b-form-group :label="FormMSG(157, 'Overtime rate')" v-uni-for="'weeklyOvertimeRate2'">
														<b-input-group>
															<b-form-input
																:disabled="isEditable() === false"
																v-model="contract.weeklyOvtTwoRate"
																v-uni-id="'weeklyOvertimeRate2'"
																type="number"
																step="0.000001"
																min="0"
																@change="computeSMG()"
																placeholder="0"
															/>
															<b-input-group-append>
																<b-input-group-text>
																	<component
																		:is="getLucideIcon(ICONS.PERCENT.name)"
																		:color="ICONS.PERCENT.color"
																		:size="16"
																	/>
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row v-if="showDefaultPayCode">
												<b-col cols="12">
													<b-form-group>
														<label for="weekOvt2PayCode" v-if="$screen.width > 992">&nbsp;</label>
														<payroll-code
															style-type="select"
															:ref="generateTextId('weekOvt2PayCode')"
															:value="contract.weekOvt2PayCode"
															@payroll-code:input="setPayroolCodeValue($event, 'weekOvt2PayCode')"
															@payroll-code:reset="resetPayroolCodeValue($event, 'weekOvt2PayCode')"
														/>
													</b-form-group>
												</b-col>
											</b-row>
										</fieldset>
									</b-col>
									<b-col
										sm="12"
										md="12"
										:lg="`${showWeeklyOvt1 && showWeeklyOvt ? 4 : !showWeeklyOvt1 && !showWeeklyOvt ? 12 : 6}`"
										:xl="`${showWeeklyOvt1 && showWeeklyOvt ? 4 : !showWeeklyOvt1 && !showWeeklyOvt ? 12 : 6}`"
										v-if="showWeeklyOvt2"
									>
										<fieldset class="card-inside">
											<legend class="card-inside">
												{{ FormMSG(158, 'Weekly overtime 3') }}
											</legend>
											<b-row>
												<b-col cols="6">
													<b-form-group :label="FormMSG(159, 'Overtime after')" v-uni-for="'weeklyOvtLimit3'">
														<input-date-time-picker
															:label="FormMSG(159, 'Overtime after')"
															:uiid="'weeklyOvtLimit3'"
															:type="'text'"
															:placeholder="'hh:mm'"
															:isSubmitted="isSubmitted"
															:item="contract.weeklyOvtThreeLimitStr"
															:nbHour="rangeHour[WORKTYPE.weekly]"
															:nbMinute="rangeMinute[WORKTYPE.weekly]"
															:isEditable="() => isEditable()"
															:fieldName="'weeklyOvtThreeLimit'"
															:workType="WORKTYPE.weekly"
															@change="handleChangeHourMin"
														/>
													</b-form-group>
												</b-col>
												<b-col cols="6">
													<b-form-group :label="FormMSG(160, 'Overtime rate')" v-uni-for="'overtimeRate3'">
														<b-input-group>
															<b-form-input
																:disabled="isEditable() === false"
																v-model="contract.weeklyOvtThreeRate"
																v-uni-id="'overtimeRate3'"
																type="number"
																step="0.000001"
																min="0"
																@change="computeSMG()"
																placeholder="0"
															/>
															<b-input-group-append>
																<b-input-group-text>
																	<component
																		:is="getLucideIcon(ICONS.PERCENT.name)"
																		:color="ICONS.PERCENT.color"
																		:size="16"
																	/>
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row v-if="showDefaultPayCode">
												<b-col cols="12">
													<b-form-group>
														<label for="weekOvt3PayCode" v-if="$screen.width > 992">&nbsp;</label>
														<payroll-code
															style-type="select"
															:ref="generateTextId('weekOvt3PayCode')"
															:value="contract.weekOvt3PayCode"
															@payroll-code:input="setPayroolCodeValue($event, 'weekOvt3PayCode')"
															@payroll-code:reset="resetPayroolCodeValue($event, 'weekOvt3PayCode')"
														/>
													</b-form-group>
												</b-col>
											</b-row>
										</fieldset>
									</b-col>
								</b-row>
							</div>
						</div>
					</b-collapse>
					<!-- End Overtimes -->
					<!-- Begin Night Time -->
					<div class="card-collapsible px-0 mt-4" v-if="showNightHours">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('night-time')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.MOON.name)" :color="ICONS.MOON.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(162, 'Night Time') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['night-time'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['night-time'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['night-time'].isVisible" v-if="showNightHours">
						<div class="content-card-collapsible inside">
							<b-row>
								<b-col
									:sm="`${$screen.width <= 576 ? 0 : 6}`"
									:md="`${$screen.width <= 576 ? 0 : 6}`"
									:lg="`${$screen.width <= 576 ? 0 : 3}`"
									:xl="`${$screen.width <= 576 ? 0 : 3}`"
									:cols="`${$screen.width <= 576 ? 6 : 0}`"
								>
									<b-form-group :label="this.FormMSG(163, 'Night time start')" v-uni-for="'nightStart'">
										<b-form-input
											:disabled="isEditable() === false"
											type="time"
											:value="contract.nightTimeStart"
											v-uni-id="'nightStart'"
											placeholder="hh:mm"
											@blur="updateNightStart($event)"
										/>
									</b-form-group>
								</b-col>
								<b-col
									:sm="`${$screen.width <= 576 ? 0 : 6}`"
									:md="`${$screen.width <= 576 ? 0 : 6}`"
									:lg="`${$screen.width <= 576 ? 0 : 3}`"
									:xl="`${$screen.width <= 576 ? 0 : 3}`"
									:cols="`${$screen.width <= 576 ? 6 : 0}`"
								>
									<b-form-group :label="this.FormMSG(164, 'Night time end')" v-uni-for="'nightEnd'">
										<b-form-input
											:disabled="isEditable() === false"
											type="time"
											:value="contract.nightTimeEnd"
											v-uni-id="'nightEnd'"
											placeholder="hh:mm"
											@blur="updateNightEnd($event)"
										/>
									</b-form-group>
								</b-col>
								<b-col
									:sm="`${$screen.width <= 576 ? 0 : 12}`"
									:md="`${$screen.width <= 576 ? 0 : 12}`"
									:lg="`${$screen.width <= 576 ? 0 : 3}`"
									:xl="`${$screen.width <= 576 ? 0 : 3}`"
									:cols="`${$screen.width <= 576 ? 12 : 0}`"
								>
									<b-form-group :label="this.FormMSG(165, 'Night hours rate')" v-uni-for="'nightRate'">
										<b-input-group class="d-flex flex-column">
											<div class="d-flex flex-row">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.nightTimeRate"
													v-uni-id="'nightRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													style="border-radius: 8px 0 0 8px"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<percent color="#06263E" :size="16" class="icon" />
													</b-input-group-text>
												</b-input-group-append>
											</div>
											<div class="info-message fix-info-message-per-diem ml-1" v-if="showDefaultPayCode">
												<div><info :size="16" /></div>
												<div class="label">
													<span style="color: #00a09c">+</span>
													{{ calculateAverageRate(contract.hourlyRate, contract.nightTimeRate) }} {{ currencySymbol }}
													{{ FormMSG(191, 'per hour') }}
												</div>
											</div>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col
									:sm="`${$screen.width <= 576 ? 0 : 12}`"
									:md="`${$screen.width <= 576 ? 0 : 12}`"
									:lg="`${$screen.width <= 576 ? 0 : 3}`"
									:xl="`${$screen.width <= 576 ? 0 : 3}`"
									:cols="`${$screen.width <= 576 ? 12 : 0}`"
									:class="`${showDefaultPayCode ? '' : 'd-flex align-items-center'}`"
									v-if="showStandard()"
								>
									<div class="info-message fix-info-message-per-diem" v-if="!showDefaultPayCode">
										<div><info :size="16" /></div>
										<div class="label">
											<span style="color: #00a09c">+</span>
											{{ calculateAverageRate(contract.hourlyRate, contract.nightTimeRate) }} {{ currencySymbol }}
											{{ FormMSG(191, 'per hour') }}
										</div>
									</div>
									<div class="w-100" :style="`margin:${$screen.width <= 992 ? '0' : '6px 0 0 0'}`" v-else>
										<b-form-group>
											<label for="nightTimePayCode" v-if="$screen.width > 992">&nbsp;</label>
											<payroll-code
												style-type="select"
												:ref="generateTextId('nightTimePayCode')"
												:value="contract.nightTimePayCode"
												@payroll-code:input="setPayroolCodeValue($event, 'nightTimePayCode')"
												@payroll-code:reset="resetPayroolCodeValue($event, 'nightTimePayCode')"
											/>
										</b-form-group>
									</div>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Night Time -->
					<!-- Begin Rest Time -->
					<div class="card-collapsible px-0 mt-4" v-if="showTooEarlyHours">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('rest-time')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.SUNMOON.name)" :color="ICONS.SUNMOON.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(166, 'Rest Time') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['rest-time'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['rest-time'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['rest-time'].isVisible" v-if="showTooEarlyHours">
						<div class="content-card-collapsible inside">
							<b-row>
								<b-col sm="6" md="6" lg="3" xl="3">
									<b-form-group :label="this.FormMSG(22, 'Min time between days')" v-uni-for="'minTimeBetweenDays'">
										<b-form-input
											:disabled="isEditable() === false"
											type="time"
											:value="contract.minTimeBetweenDays"
											v-uni-id="'minTimeBetweenDays'"
											@blur="updateMinTimeBetweenDays($event)"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="6" md="6" lg="3" xl="3">
									<b-form-group :label="this.FormMSG(149, 'Min time between weeks')" v-uni-for="'minBetW'">
										<input-date-time-picker
											:label="this.FormMSG(149, 'Min time between weeks')"
											:uiid="'minBetW'"
											:type="'text'"
											:placeholder="'hh:mm'"
											:isSubmitted="isSubmitted"
											:item="contract.minTimeBetweenWeekStr"
											:nbHour="rangeHour[WORKTYPE.weekly]"
											:nbMinute="rangeMinute[WORKTYPE.weekly]"
											:isEditable="() => isEditable()"
											:fieldName="'minTimeBetweenWeek'"
											:workType="WORKTYPE.weekly"
											@change="handleChangeHourMin"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="6" md="6" lg="3" xl="3">
									<b-form-group :label="this.FormMSG(16, 'Aniticipated hours rate')" v-uni-for="'anticRate'">
										<b-input-group class="d-flex flex-column">
											<div class="d-flex flex-row">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.hourBeforeTimeRate"
													v-uni-id="'anticRate'"
													type="number"
													step="0.000001"
													min="0"
													placeholder="0"
													style="border-radius: 8px 0 0 8px"
												/>
												<b-input-group-append>
													<b-input-group-text>
														<percent color="#06263E" :size="16" class="icon" />
													</b-input-group-text>
												</b-input-group-append>
											</div>
											<div class="info-message fix-info-message-per-diem ml-1" v-if="showDefaultPayCode">
												<div><info :size="16" /></div>
												<div class="label">
													<span style="color: #00a09c">+</span>
													{{ calculateAverageRate(contract.hourlyRate, contract.hourBeforeTimeRate) }} {{ currencySymbol }}
													{{ FormMSG(191, 'per hour') }}
												</div>
											</div>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="6" md="6" lg="3" xl="3" :class="`${showDefaultPayCode ? '' : 'd-flex align-items-center'}`" v-if="showStandard()">
									<div class="info-message fix-info-message-per-diem" v-if="!showDefaultPayCode">
										<div><info :size="16" /></div>
										<div class="label">
											<span style="color: #00a09c">+</span>
											{{ calculateAverageRate(contract.hourlyRate, contract.hourBeforeTimeRate) }} {{ currencySymbol }}
											{{ FormMSG(191, 'per hour') }}
										</div>
									</div>
									<div class="w-100" :style="`margin:${$screen.width <= 576 ? '0' : '6px 0 0 0'}`" v-else>
										<b-form-group>
											<label for="restPayCode" v-if="$screen.width > 576">&nbsp;</label>
											<payroll-code
												style-type="select"
												:ref="generateTextId('restPayCode')"
												:value="contract.restPayCode"
												@payroll-code:input="setPayroolCodeValue($event, 'restPayCode')"
												@payroll-code:reset="resetPayroolCodeValue($event, 'restPayCode')"
											/>
										</b-form-group>
									</div>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Rest Time -->
					<!-- Begin Transport -->
					<div class="card-collapsible px-0 mt-4" v-if="showTransport">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('transport')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.BUS.name)" :color="ICONS.BUS.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(169, 'Transport') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['transport'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['transport'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['transport'].isVisible" v-if="showTransport">
						<div class="content-card-collapsible inside">
							<b-row>
								<b-col sm="6" md="6" :lg="`${showDefaultPayCode ? 4 : 6}`" :xl="`${showDefaultPayCode ? 4 : 6}`">
									<b-form-group :label="FormMSG(146, 'Paid if above')" v-uni-for="'transportPaid'">
										<input-date-time-picker
											:label="FormMSG(146, 'Paid if above')"
											:uiid="'transportPaid'"
											:type="'text'"
											:placeholder="'hh:mm'"
											:isSubmitted="isSubmitted"
											:item="contract.transportPaidAfterStr"
											:nbHour="rangeHour[WORKTYPE.daily]"
											:nbMinute="rangeMinute[WORKTYPE.daily]"
											:isEditable="() => isEditable()"
											:fieldName="'transportPaidAfter'"
											:workType="WORKTYPE.daily"
											@change="handleChangeHourMin"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="6" md="6" :lg="`${showDefaultPayCode ? 4 : 6}`" :xl="`${showDefaultPayCode ? 4 : 6}`">
									<b-form-group :label="FormMSG(147, 'Amount (/h)')" v-uni-for="'transportRate'">
										<b-input-group>
											<b-form-input
												:disabled="isEditable() === false"
												v-model="contract.transportRate"
												v-uni-id="'transportRate'"
												type="number"
												step="0.000001"
												min="0"
												placeholder="0"
											/>
											<b-input-group-append>
												<b-input-group-text>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="4" xl="4" :style="`margin:${$screen.width <= 992 ? '0' : '6px 0 0 0'}`" v-if="showDefaultPayCode">
									<b-form-group v-if="showDefaultPayCode">
										<label for="transportPayCode" v-if="$screen.width > 992">&nbsp;</label>
										<payroll-code
											style-type="select"
											:ref="generateTextId('transportPayCode')"
											:value="contract.transportPayCode"
											@payroll-code:input="setPayroolCodeValue($event, 'transportPayCode')"
											@payroll-code:reset="resetPayroolCodeValue($event, 'transportPayCode')"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Transport -->
					<!-- Begin Special days rates -->
					<div class="card-collapsible px-0 mt-4" v-if="showSixthDayRate || showSeventhDayRate || showSundayRate">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('special-days-rates')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.STAR.name)" :color="ICONS.STAR.color" :size="22" />
								<div class="fz-1-35 ml-3">
									{{ FormMSG(170, 'Special days rates') }}
								</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['special-days-rates'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['special-days-rates'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['special-days-rates'].isVisible" v-if="showSixthDayRate || showSeventhDayRate || showSundayRate">
						<div class="content-card-collapsible inside">
							<b-row>
								<b-col
									sm="12"
									md="12"
									:lg="`${
										(!showSixthDayRate && !showSeventhDayRate) ||
										(!showSixthDayRate && showSeventhDayRate) ||
										(showSixthDayRate && !showSeventhDayRate)
											? showDefaultPayCode
												? 3
												: 6
											: showDefaultPayCode
											? 3
											: 4
									}`"
									:xl="`${
										(!showSixthDayRate && !showSeventhDayRate) ||
										(!showSixthDayRate && showSeventhDayRate) ||
										(showSixthDayRate && !showSeventhDayRate)
											? showDefaultPayCode
												? 3
												: 6
											: showDefaultPayCode
											? 3
											: 4
									}`"
									v-if="showSundayRate"
								>
									<b-form-group :label="FormMSG(102, 'Sunday and public holiday rate')" v-uni-for="'sundayRate'">
										<b-input-group>
											<b-form-input
												:disabled="isEditable() === false"
												v-model="contract.sundayPublicHolidayFactor"
												v-uni-id="'sundayRate'"
												type="number"
												step="0.000001"
												min="0"
												placeholder="0"
											/>
											<b-input-group-append>
												<b-input-group-text>
													<percent color="#06263E" :size="16" class="icon" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col
									sm="12"
									md="12"
									lg="3"
									xl="3"
									v-if="showDefaultPayCode && showSundayRate"
									:style="`margin:${$screen.width <= 992 ? '0' : '6px 0 0 0'}`"
								>
									<b-form-group>
										<label for="sundayPayCode" v-if="$screen.width > 992">&nbsp;</label>
										<payroll-code
											style-type="select"
											:ref="generateTextId('sundayPayCode')"
											:value="contract.sundayPayCode"
											@payroll-code:input="setPayroolCodeValue($event, 'sundayPayCode')"
											@payroll-code:reset="resetPayroolCodeValue($event, 'sundayPayCode')"
										/>
									</b-form-group>
								</b-col>
								<b-col
									sm="12"
									md="12"
									:lg="`${
										(!showSundayRate && !showSeventhDayRate) ||
										(!showSundayRate && showSeventhDayRate) ||
										(showSundayRate && !showSeventhDayRate)
											? showDefaultPayCode
												? 3
												: 6
											: showDefaultPayCode
											? 3
											: 4
									}`"
									:xl="`${
										(!showSundayRate && !showSeventhDayRate) ||
										(!showSundayRate && showSeventhDayRate) ||
										(showSundayRate && !showSeventhDayRate)
											? showDefaultPayCode
												? 3
												: 6
											: showDefaultPayCode
											? 3
											: 4
									}`"
									v-if="showSixthDayRate"
								>
									<b-form-group :label="FormMSG(100, '6th day rate')" v-uni-for="'sixthDay'">
										<b-input-group>
											<b-form-input
												:disabled="isEditable() === false"
												v-model="contract.sixthDay"
												type="number"
												step="0.000001"
												min="0"
												v-uni-id="'sixthDay'"
											/>
											<b-input-group-append>
												<b-input-group-text>
													<percent color="#06263E" :size="16" class="icon" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col
									sm="12"
									md="12"
									lg="3"
									xl="3"
									v-if="showDefaultPayCode && showSixthDayRate"
									:style="`margin:${$screen.width <= 992 ? '0' : '6px 0 0 0'}`"
								>
									<b-form-group>
										<label for="seventhDayPayCode" v-if="$screen.width > 992">&nbsp;</label>
										<payroll-code
											style-type="select"
											:ref="generateTextId('seventhDayPayCode')"
											:value="contract.seventhDayPayCode"
											@payroll-code:input="setPayroolCodeValue($event, 'seventhDayPayCode')"
											@payroll-code:reset="resetPayroolCodeValue($event, 'seventhDayPayCode')"
										/>
									</b-form-group>
								</b-col>
								<b-col
									sm="12"
									md="12"
									:lg="`${
										(!showSixthDayRate && !showSundayRate) || (!showSixthDayRate && showSundayRate) || (showSixthDayRate && !showSundayRate)
											? showDefaultPayCode
												? 3
												: 6
											: showDefaultPayCode
											? 3
											: 4
									}`"
									:xl="`${
										(!showSixthDayRate && !showSundayRate) || (!showSixthDayRate && showSundayRate) || (showSixthDayRate && !showSundayRate)
											? showDefaultPayCode
												? 3
												: 6
											: showDefaultPayCode
											? 3
											: 4
									}`"
									v-if="showSeventhDayRate"
								>
									<b-form-group :label="FormMSG(101, '7th day rate')" v-uni-for="'seventhDay'">
										<b-input-group>
											<b-form-input
												:disabled="isEditable() === false"
												v-model="contract.seventhDay"
												type="number"
												step="0.000001"
												min="0"
												v-uni-id="'seventhDay'"
											/>
											<b-input-group-append>
												<b-input-group-text>
													<percent color="#06263E" :size="16" class="icon" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col
									sm="12"
									md="12"
									lg="3"
									xl="3"
									v-if="showDefaultPayCode && showSeventhDayRate"
									:style="`margin:${$screen.width <= 992 ? '0' : '6px 0 0 0'}`"
								>
									<b-form-group>
										<label for="sixthDayPayCode" v-if="$screen.width > 992">&nbsp;</label>
										<payroll-code
											style-type="select"
											:ref="generateTextId('sixthDayPayCode')"
											:value="contract.sixthDayPayCode"
											@payroll-code:input="setPayroolCodeValue($event, 'sixthDayPayCode')"
											@payroll-code:reset="resetPayroolCodeValue($event, 'sixthDayPayCode')"
										/>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="4" xl="4">
									<b-form-group :label="FormMSG(329, 'Public holiday calendar')">
										<b-form-select v-model="contract.publicHolidayCalendar" :options="publicHolidays" />
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Special days rates -->
					<!-- Begin Lunch -->
					<div class="card-collapsible px-0 mt-4" v-if="showLunch">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('lunch')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.UTENSIL.name)" :color="ICONS.UTENSIL.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(171, 'Lunch') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['lunch'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['lunch'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['lunch'].isVisible" v-if="showLunch">
						<div class="content-card-collapsible inside">
							<b-row>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-form-group :label="FormMSG(117, 'Min lunch')" v-uni-for="'lunchMinimum'">
										<input-date-time-picker
											:label="FormMSG(117, 'Min lunch')"
											:uiid="'wbnh'"
											:type="'text'"
											:placeholder="'hh:mm'"
											:isSubmitted="isSubmitted"
											:item="contract.lunchMinimumStr"
											:nbHour="rangeHour[WORKTYPE.daily]"
											:nbMinute="rangeMinute[WORKTYPE.daily]"
											:isEditable="() => isEditable()"
											:fieldName="'lunchMinimum'"
											:workType="WORKTYPE.daily"
											@change="handleChangeHourMin"
										/>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6" v-if="showStandard()">
									<b-form-group :label="FormMSG(217, 'Min lunch for continuous day')" v-uni-for="'lunchMinimumForContDay'">
										<input-date-time-picker
											:label="FormMSG(217, 'Min lunch for continuous day')"
											:uiid="'wbnh'"
											:type="'text'"
											:placeholder="'hh:mm'"
											:isSubmitted="isSubmitted"
											:item="contract.lunchMinimumForContDayStr"
											:nbHour="rangeHour[WORKTYPE.daily]"
											:nbMinute="rangeMinute[WORKTYPE.daily]"
											:isEditable="() => isEditable()"
											:fieldName="'lunchMinimumForContDay'"
											:workType="WORKTYPE.daily"
											@change="handleChangeHourMin"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Lunch -->
					<!-- Begin Before call time rate -->
					<div class="card-collapsible px-0 mt-4" v-if="showBeforeCallTime">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('before-call-time-rate')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.ALARM_CHECK.name)" :color="ICONS.ALARM_CHECK.color" :size="22" />
								<div class="fz-1-35 ml-3">
									{{ showStandard() ? FormMSG(172, 'Before call time rate') : FormMSG(198, 'Early shooting day rate') }}
								</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['before-call-time-rate'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['before-call-time-rate'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['before-call-time-rate'].isVisible" v-if="showBeforeCallTime">
						<div class="content-card-collapsible inside">
							<div class="sub-title-layout pb-3" style="font-size: 0.9rem; color: rgba(6, 38, 62, 0.64)">
								{{
									FormMSG(
										173,
										'You can use this section to define a specific rate for the crew members who start to work before the call time during a shooting day.'
									)
								}}
							</div>
							<b-row>
								<b-col sm="12" md="12" :lg="`${showDefaultPayCode ? 4 : 6}`" :xl="`${showDefaultPayCode ? 4 : 6}`">
									<b-form-group :label="FormMSG(174, 'Before call time rate')" v-uni-for="'beforeCallTimeRate'">
										<b-input-group>
											<b-form-input
												:disabled="isEditable() === false"
												v-model="contract.beforeCallTimeRate"
												type="number"
												step="0.000001"
												min="0"
												v-uni-id="'beforeCallTimeRate'"
											/>
											<b-input-group-append>
												<b-input-group-text>
													<percent color="#06263E" :size="16" class="icon" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
										<div class="info-message" v-if="showStandard()">
											<div><info :size="16" /></div>
											<div class="label">
												<span style="color: #00a09c">+</span>
												{{ calculateAverageRate(contract.hourlyRate, contract.beforeCallTimeRate) }} {{ currencySymbol }}
												{{ FormMSG(176, 'per hour') }}
											</div>
										</div>
									</b-form-group>
								</b-col>
								<b-col
									class="d-flex align-items-center mb-2"
									sm="12"
									md="12"
									:lg="`${showDefaultPayCode ? 4 : 6}`"
									:xl="`${showDefaultPayCode ? 4 : 6}`"
								>
									<b-form-checkbox :disabled="isEditable() === false" size="lg" class="pj-cb pb-1" v-model="contract.ifWorkAboveDailyHours">{{
										FormMSG(177, 'Applies if worked hours above daily hours')
									}}</b-form-checkbox>
								</b-col>
								<b-col sm="12" md="12" lg="4" xl="4" v-if="showDefaultPayCode" :style="`margin:${$screen.width <= 576 ? '0' : '6px 0 0 0'}`">
									<label for="beforeTimePayCode" v-if="$screen.width > 576">&nbsp;</label>
									<payroll-code
										style-type="select"
										:ref="generateTextId('beforeTimePayCode')"
										:value="contract.beforeTimePayCode"
										@payroll-code:input="setPayroolCodeValue($event, 'beforeTimePayCode')"
										@payroll-code:reset="resetPayroolCodeValue($event, 'beforeTimePayCode')"
									/>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Before call time rate -->
					<!-- Begin Allowances -->
					<div class="card-collapsible px-0 mt-4">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('allowances')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.AWARD.name)" :color="ICONS.AWARD.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(178, 'Allowances') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['allowances'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['allowances'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['allowances'].isVisible">
						<div class="content-card-collapsible inside">
							<div class="sub-title-layout pb-3">
								{{ FormMSG(179, 'General allowances') }}
							</div>
							<b-row>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useTravelDailyRate"
												@change="handleAccessGeneralAllowance($event, 'kmRate')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:lg="`${$screen.width <= 576 ? 0 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : 5}`"
											:cols="`${$screen.width <= 576 ? 10 : 0}`"
										>
											<b-form-group :label="`${FormMSG(107, 'Travel rate')} (/${distanceUnit})`" v-uni-for="'kmRate'">
												<b-input-group>
													<b-form-input
														ref="kmRate"
														:disabled="isEditable() === false || contract.useTravelDailyRate === false"
														v-model="contract.kmRate"
														v-uni-id="'kmRate'"
														type="number"
														step="0.0001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : 5}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="travelAllowancePayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('travelAllowancePayCode')"
													:value="contract.travelAllowancePayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'travelAllowancePayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'travelAllowancePayCode')"
												/>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
											v-else-if="!showDefaultPayCode && $screen.width > 576"
										>
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useCarAllowance"
												@change="handleAccessGeneralAllowance($event, 'carAllowance')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
										>
											<b-form-group :label="FormMSG(180, 'Car')" v-uni-for="'allowanceCar'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useCarAllowance === false"
														v-model="contract.carAllowance"
														v-uni-id="'allowanceCar'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
											style="margin: 6px 0 0 0"
										>
											<label for="allowanceCarType">&nbsp;</label>
											<b-input-group>
												<b-form-select
													:disabled="isEditable() === false"
													v-model="contract.carAllowanceRate"
													:options="periodicalOptions"
												/>
											</b-input-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 4 : 5}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="carAllowancePayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('carAllowancePayCode')"
													:value="contract.carAllowancePayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'carAllowancePayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'carAllowancePayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.usePhoneAllowance"
												@change="handleAccessGeneralAllowance($event, 'phoneAllowance')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
										>
											<b-form-group :label="FormMSG(181, 'Phone')" v-uni-for="'allowancePhone'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.usePhoneAllowance === false"
														v-model="contract.phoneAllowance"
														v-uni-id="'allowancePhone'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
											style="margin: 6px 0 0 0"
										>
											<label for="allowancePhoneType">&nbsp;</label>
											<b-input-group>
												<b-form-select
													:disabled="isEditable() === false"
													v-model="contract.phoneAllowanceRate"
													:options="periodicalOptions"
												/>
											</b-input-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 4 : 5}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="phoneAllowancePayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('phoneAllowancePayCode')"
													:value="contract.phoneAllowancePayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'phoneAllowancePayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'phoneAllowancePayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useComputerAllowance"
												@change="handleAccessGeneralAllowance($event, 'computerAllowance')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
										>
											<b-form-group :label="FormMSG(182, 'Computer')" v-uni-for="'allowanceComputer'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useComputerAllowance === false"
														v-model="contract.computerAllowance"
														v-uni-id="'allowanceComputer'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
											style="margin: 6px 0 0 0"
										>
											<label for="allowanceComputerType">&nbsp;</label>
											<b-input-group>
												<b-form-select
													:disabled="isEditable() === false"
													v-model="contract.computerAllowanceRate"
													:options="periodicalOptions"
												/>
											</b-input-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 4 : 5}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="computerAllowancePayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('computerAllowancePayCode')"
													:value="contract.computerAllowancePayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'computerAllowancePayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'computerAllowancePayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useBoxKitAllowance"
												@change="handleAccessGeneralAllowance($event, 'boxKitAllowance')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
										>
											<b-form-group :label="FormMSG(183, 'Box kit')" v-uni-for="'allowanceBoxKit'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useBoxKitAllowance === false"
														v-model="contract.boxKitAllowance"
														v-uni-id="'allowanceBoxKit'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
											style="margin: 6px 0 0 0"
										>
											<label for="allowanceBoxKitType">&nbsp;</label>
											<b-input-group>
												<b-form-select
													:disabled="isEditable() === false"
													v-model="contract.boxKitAllowanceRate"
													:options="periodicalOptions"
												/>
											</b-input-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 4 : 5}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="boxKitAllowancePayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('boxKitAllowancePayCode')"
													:value="contract.boxKitAllowancePayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'boxKitAllowancePayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'boxKitAllowancePayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useProductionFeeAllowance"
												@change="handleAccessGeneralAllowance($event, 'productionFeeAllowance')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
										>
											<b-form-group :label="FormMSG(184, 'Production fee')" v-uni-for="'productionFeeAllowance'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useProductionFeeAllowance === false"
														v-model="contract.productionFeeAllowance"
														v-uni-id="'productionFeeAllowance'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 3 : 5}`"
											:cols="`${$screen.width <= 576 ? 5 : 0}`"
											style="margin: 6px 0 0 0"
										>
											<label for="productionFeeAllowanceType">&nbsp;</label>
											<b-input-group>
												<b-form-select
													:disabled="isEditable() === false"
													v-model="contract.productionFeeAllowanceRate"
													:options="periodicalOptions"
												/>
											</b-input-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 4 : 5}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="productionFeeAllowancePayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('productionFeeAllowancePayCode')"
													:value="contract.productionFeeAllowancePayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'productionFeeAllowancePayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'productionFeeAllowancePayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<div class="divider" />
							<div class="sub-title-layout pb-3" v-if="showLunchPerDiem || showHotelPerDiem || showDinnerPerDiem || showAbroadPerDiem">
								{{ FormMSG(185, 'Per diem allowances') }}
							</div>
							<b-row>
								<b-col sm="12" md="12" lg="6" xl="6" v-if="showLunchPerDiem">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useLunchPerDiem"
												@change="handleAccessGeneralAllowance($event, 'lunchPerDiem')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:cols="`${$screen.width <= 576 ? 10 : 0}`"
										>
											<b-form-group :label="FormMSG(108, 'Lunch per diem')" v-uni-for="'lunchPerDiem'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useLunchPerDiem === false"
														v-model="contract.lunchPerDiem"
														v-uni-id="'lunchPerDiem'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 5 : 10}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="lunchPerDiemPayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('lunchPerDiemPayCode')"
													:value="contract.lunchPerDiemPayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'lunchPerDiemPayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'lunchPerDiemPayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6" v-if="showDinnerPerDiem">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useDinnerPerDiem"
												@change="handleAccessGeneralAllowance($event, 'dinnerPerDiem')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:cols="`${$screen.width <= 576 ? 10 : 0}`"
										>
											<b-form-group :label="FormMSG(186, 'Diner per diem')" v-uni-for="'dinerPerDiem'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useDinnerPerDiem === false"
														v-model="contract.dinnerPerDiem"
														v-uni-id="'dinerPerDiem'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 5 : 10}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="dinerPerDiemPayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('dinerPerDiemPayCode')"
													:value="contract.dinerPerDiemPayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'dinerPerDiemPayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'dinerPerDiemPayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="12" lg="6" xl="6" v-if="showHotelPerDiem">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useHotelPerDiem"
												@change="handleAccessGeneralAllowance($event, 'hotelPerDiem')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:cols="`${$screen.width <= 576 ? 10 : 0}`"
										>
											<b-form-group :label="FormMSG(109, 'Hotel per diem')" v-uni-for="'hotelPerDiem'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useHotelPerDiem === false"
														v-model="contract.hotelPerDiem"
														v-uni-id="'hotelPerDiem'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 5 : 10}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="hotelPerDiemPayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('hotelPerDiemPayCode')"
													:value="contract.hotelPerDiemPayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'hotelPerDiemPayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'hotelPerDiemPayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6" v-if="showAbroadPerDiem">
									<b-row>
										<b-col class="d-flex justify-content-end align-items-center" :class="`${$screen.width <= 576 ? 'mt-3' : ''}`" cols="2">
											<b-form-checkbox
												:disabled="isEditable() === false"
												class="fix-check-allowance"
												size="lg"
												v-model="contract.useAbroadPerDiem"
												@change="handleAccessGeneralAllowance($event, 'abroadPerDiem')"
											/>
										</b-col>
										<b-col
											:sm="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:md="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:lg="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:xl="`${$screen.width <= 576 ? 0 : showDefaultPayCode ? 5 : 10}`"
											:cols="`${$screen.width <= 576 ? 10 : 0}`"
										>
											<b-form-group :label="FormMSG(187, 'Abroad per diem')" v-uni-for="'abroadPerDiem'">
												<b-input-group>
													<b-form-input
														:disabled="isEditable() === false || contract.useAbroadPerDiem === false"
														v-model="contract.abroadPerDiem"
														v-uni-id="'abroadPerDiem'"
														type="number"
														step="0.000001"
														min="0"
														placeholder="0"
													/>
													<b-input-group-append>
														<b-input-group-text>
															<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="2" v-if="$screen.width <= 576" />
										<b-col
											:cols="`${$screen.width <= 576 ? 10 : showDefaultPayCode ? 5 : 10}`"
											v-if="showDefaultPayCode"
											:style="`margin:${$screen.width <= 576 ? '-20px 0 0 0' : '6px 0 0 0'}`"
										>
											<b-form-group>
												<label for="abroadPerDiemPayCode">&nbsp;</label>
												<payroll-code
													style-type="select"
													:ref="generateTextId('abroadPerDiemPayCode')"
													:value="contract.abroadPerDiemPayCode"
													@payroll-code:input="setPayroolCodeValue($event, 'abroadPerDiemPayCode')"
													@payroll-code:reset="resetPayroolCodeValue($event, 'abroadPerDiemPayCode')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Allowances -->
					<!-- Begin Note -->
					<div class="card-collapsible px-0 mt-4">
						<div
							class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
							@click="handleClickHeader('note')"
						>
							<div class="title w-90">
								<component :is="getLucideIcon(ICONS.MENU.name)" :color="ICONS.MENU.color" :size="22" />
								<div class="fz-1-35 ml-3">{{ FormMSG(188, 'Note') }}</div>
							</div>
							<div class="cursor-pointer d-flex justify-content-end w-10">
								<component
									v-if="!configAccordion['note'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
									:color="ICONS.CHEVRON_DOWN.color"
									:size="22"
								/>
								<component
									v-if="configAccordion['note'].isVisible"
									:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
									:color="ICONS.CHEVRON_UP.color"
									:size="22"
								/>
							</div>
						</div>
					</div>
					<b-collapse :visible="configAccordion['note'].isVisible">
						<div class="content-card-collapsible inside">
							<b-row>
								<b-col sm="3" md="3" lg="3" xl="3">
									<b-row>
										<b-col cols="12">
											<b-form-group :label="FormMSG(304, 'Contract type')" v-uni-for="'contractType'">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.contractType"
													type="text"
													v-uni-id="'contractType'"
													autocomplete="off"
													:placeholder="FormMSG(305, 'Your contract type ...')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-form-group :label="FormMSG(296, 'Misc.')" v-uni-for="'misc'">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.contractReference"
													type="text"
													v-uni-id="'misc'"
													autocomplete="off"
													:placeholder="FormMSG(297, 'Your contract reference ...')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-form-group :label="FormMSG(314, 'Collective agreement code')" v-uni-for="'collectiveAgreement'">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.collectiveAgreement"
													type="text"
													v-uni-id="'collectiveAgreement'"
													autocomplete="off"
													:placeholder="FormMSG(315, 'Your collective agreement code ...')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-form-group :label="FormMSG(316, 'Echelon')" v-uni-for="'echelon'">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.echelon"
													type="text"
													v-uni-id="'echelon'"
													autocomplete="off"
													:placeholder="FormMSG(317, 'Your echelon ...')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12">
											<b-form-group :label="FormMSG(318, 'Analytic code')" v-uni-for="'analyticCode'">
												<b-form-input
													:disabled="isEditable() === false"
													v-model="contract.analyticCode"
													type="text"
													v-uni-id="'analyticCode'"
													autocomplete="off"
													:placeholder="FormMSG(319, 'Your analytic code ...')"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="9" md="9" lg="9" xl="9">
									<b-form-group v-uni-for="'note'">
										<b-form-textarea
											:disabled="isEditable() === false"
											v-model="contract.note"
											type="textarea"
											:rows="`${$screen.width <= 576 ? 6 : 17}`"
											:placeholder="FormMSG(189, 'Enter a note here ...')"
											autocomplete="off"
											id="note"
											class="pb-2"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<!-- End Note -->
				</div>
			</b-collapse>
		</div>
		<div class="px-4 pt-3" v-if="!isFilmSingleUserFree && !isFilmSingleUser && !hideActions">
			<b-row class="d-flex justify-content-center align-items-center" :class="`${$screen.width <= 576 ? 'footer-fixed' : ''}`">
				<b-col sm="12" md="12" lg="4" xl="4">
					<b-button size="md" variant="outline-secondary" class="px-4" @click="copyContract" block>
						{{ FormMSG(192, 'Copy deal memo') }}
					</b-button>
				</b-col>
				<b-col sm="12" md="12" lg="4" xl="4" :class="`${$screen.width <= 992 ? 'my-2' : ''}`">
					<b-button size="md" variant="outline-secondary" class="px-4" @click="pasteContract" :disabled="contractCopied === 0" block>
						{{ FormMSG(193, 'Paste deal memo') }}
					</b-button>
				</b-col>
				<b-col sm="12" md="12" lg="4" xl="4">
					<b-button
						size="md"
						variant="outline-secondary"
						class="px-4"
						@click="saveAsDealMemoTemplate($event)"
						:disabled="disableSaveDealMemoTemplate"
						block
					>
						{{ FormMSG(194, 'Save as deal memo template') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { isNil, greaterThanZero, hourMinuteValid, omit, generateSecureId } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { createUniqIdsMixin } from 'vue-uniq-ids';
const uniqIdsMixin = createUniqIdsMixin(/* options */);
import { Euro, Info, Percent, AlarmPlus, Bus, Award, Moon } from 'lucide-vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { store } from '@/store';
import CurrencySvg from '@/components/Svg/Currency';
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import globalMixin from '@/mixins/global.mixin';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';
import { useWorktype, useRangeHour, useRangeMinute } from '../utils/utils';
import PayrollCode from '@/components/Spaiectacle/PayrollCode';
import { dataCcn2642 } from '@/cruds/contract.crud';
import { getProjectPublicHolidayCalendar } from '@/cruds/holidays.crud';
import * as _ from 'lodash';

export default {
	name: 'memoDealVue',
	mixins: [languageMessages, uniqIdsMixin, isSingleProjectMixin, validationMixin, globalMixin],
	props: {
		disableEdit: {
			type: Boolean,
			required: false,
			default: false
		},
		disableSave: {
			type: Number,
			required: false,
			default: 0
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		displayCopyPaste: false,
		startDateUpdate: {
			type: String,
			required: false,
			default: null
		},
		endDateUpdate: {
			type: String,
			required: false,
			default: null
		},
		daysForeseenUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		employeeStatusUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		costCenterPrepsTypeUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		costCenterShootingTypeUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		costCenterWrapTypeUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		companyCostFactorUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		documentPackageIdUpdate: {
			type: [String, Number],
			required: false,
			default: '0'
		},
		isSubmitted: {
			type: Boolean,
			required: false,
			default: false
		},
		fillClassContainer: {
			type: String,
			required: false,
			default: 'container-layout' // default empty, or 'container-layout'
		},
		disableSaveDealMemoTemplate: {
			type: Boolean,
			required: false,
			default: false
		},
		hideActions: {
			type: Boolean,
			required: false,
			default: false
		},
		isAutomaticSentContractUpdate: {
			type: Boolean,
			required: false,
			default: false
		},
		showPayCode: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		Euro,
		Info,
		Percent,
		AlarmPlus,
		Bus,
		Award,
		Moon,
		CurrencySvg,
		InputDateTimePicker,
		PayrollCode
	},
	data() {
		return {
			contractCopied: 0,
			smgBase: 0,
			showDateTimePickers: false,
			rangeHour: {},
			rangeMinute: {},
			WORKTYPE: {},
			contract: {
				defaultDayType: 0,
				dailyRate: 0,
				travelDailyRate: 0,
				hourlyRate: 35,
				overtimeRate: 0,
				nightTimeRate: 0,
				hourBeforeTimeRate: 0,
				kmRate: 0,
				lunchPerDiem: 0,
				hotelPerDiem: 0,
				dailyRightTransfer: 0,
				nightTimeStart: '2019-01-01T00:00:00Z',
				nightTimeEnd: '2019-01-01T00:00:00Z',
				minTimeBetweenDays: '2019-01-01T00:00:00Z',
				lunchMinimum: 0,
				totMinPerDayStr: '00:00',
				totMinPerWeekStr: '00:00',
				sixthDay: 100,
				seventhDay: 100,
				timeManagementType: 0,
				weeklyOvertime: false,
				workSixDays: 0,
				weeklyFiveDaysMinimumSalary: 995,
				weeklySixDaysMinimumSalary: 1141,
				weeklyFiveDaysEffNumHours: 43,
				weeklyFiveDaysEquNumHours: 46,
				weeklySixDaysEffNumHours: 52,
				weeklySixDaysEquNumHours: 56,
				weeklyBonusAmount: 800,
				weeklyFiveDaysRefSalary: 1501,
				weeklySixDaysRefSalary: 1985,
				weeklyOvtOneLimit: 35,
				weeklyOvtOneRate: 125,
				weeklyOvtTwoLimit: 43,
				weeklyOvtTwoRate: 150,
				weeklyOvtThreeLimit: 48,
				weeklyOvtThreeRate: 175,
				weeklyBaseEffNumHours: 39,
				hourlyRateAnnexThree: 23,
				weeklyBonusAmountFiveDays: 950,
				weeklyBonusAmountSixDays: 1200,
				weeklyBaseRefSalary: 1415,
				weeklyBaseMinimumSalary: 970,
				weeklyFiveDaysMinimumSalaryAnThree: 910,
				weeklySixDaysMinimumSalaryAnThree: 1140,
				transportRate: 0,
				transportPaidAfter: 0,
				ovtLimit1: 0,
				ovtLimit2: 0,
				ovtRate1: 100,
				ovtRate2: 100,
				sundayPublicHolidayFactor: 100,
				companyCostFactor: 1,
				minTimeBetweenWeek: 0,
				transportPaidAfterStr: '00:00',
				ovtLimit1Str: '00:00',
				ovtLimit2Str: '00:00',
				minTimeBetweenWeekStr: '00:00',
				totMinPerDayForContDay: 0,
				totMinPerDayForContDayStr: '00:00',
				lunchMinimumForContDay: 0,
				weeklyBaseEffNumHoursStr: '00:00',
				weeklyOvtOneLimitStr: '00:00',
				weeklyOvtTwoLimitStr: '00:00',
				weeklyOvtThreeLimitStr: '00:00',
				lunchMinimumStr: '00:00',
				lunchMinimumForContDayStr: '00:00',
				minimumHourGuaranteedStr: '00:00',
				beforeCallTimeRate: 0,
				minimumHourGuaranteedType: 0,
				carAllowanceRate: 0,
				phoneAllowanceRate: 0,
				computerAllowanceRate: 0,
				boxKitAllowanceRate: 0,
				productionFeeAllowanceRate: 0,
				weeklyFiveDaysEffNumHoursStr: '00:00',
				weeklyFiveDaysEquNumHoursStr: '00:00',
				weeklySixDaysEffNumHoursStr: '00:00',
				weeklySixDaysEquNumHoursStr: '00:00',
				contractReference: '',
				contractType: '',
				dailyOvertime: false,
				ovtLimit: 0,
				ovtLimitContinuousDay: 0,
				ovtLimitStr: '00:00',
				ovtLimitContinuousDayStr: '00:00',
				monthlyRate: 0,
				useTravelDailyRate: false,
				baseSalary1PayCode: null,
				baseSalary2PayCode: null,
				baseSalary3PayCode: null,
				baseSalary4PayCode: null,
				dayOvt1PayCode: null,
				dayOvt2PayCode: null,
				dayOvt3PayCode: null,
				weekOvt1PayCode: null,
				weekOvt2PayCode: null,
				weekOvt3PayCode: null,
				nightTimePayCode: null,
				restPayCode: null,
				transportPayCode: null,
				sundayPayCode: null,
				seventhDayPayCode: null,
				sixthDayPayCode: null,
				beforeTimePayCode: null,
				travelAllowancePayCode: null,
				carAllowancePayCode: null,
				phoneAllowancePayCode: null,
				computerAllowancePayCode: null,
				boxKitAllowancePayCode: null,
				productionFeeAllowancePayCode: null,
				lunchPerDiemPayCode: null,
				dinerPerDiemPayCode: null,
				hotelPerDiemPayCode: null,
				abroadPerDiemPayCode: null,
				collectiveAgreement: '',
				echelon: '',
				analyticCode: '',
				cdiMonthlyReferenceSalary: 0,
				publicHolidayCalendar: 0
			},
			contractContent: {},
			showTooltipBcf: false,
			configAccordion: {
				'work-details': {
					isVisible: true
				},
				'main-details': {
					isVisible: true
				},
				overtimes: {
					isVisible: false
				},
				'night-time': {
					isVisible: false
				},
				'rest-time': {
					isVisible: false
				},
				transport: {
					isVisible: false
				},
				'special-days-rates': {
					isVisible: false
				},
				lunch: {
					isVisible: false
				},
				'before-call-time-rate': {
					isVisible: false
				},
				allowances: {
					isVisible: false
				},
				note: {
					isVisible: true
				},
				'work-info': {
					isVisible: true
				}
			},
			localSubmitted: false,
			standardPayCodeTitle: '',
			showDefaultPayCode: true,
			publicHolidays: []
		};
	},
	async created() {
		this.setActionInputDateTimePicker();
		await this.getPublicHolidays();
	},
	mounted() {
		this.loadEditDataToContract();
	},
	watch: {
		contract: {
			handler(elem) {
				// console.log('avant val field emit change1:', elem);
				if (!isNil(elem)) {
					this.validateFields();
					const time = /(\d{2}:\d{2}:\d{2})/g;
					this.contract.nightTimeStart =
						elem.nightTimeStart.match(time) && elem.nightTimeStart.match(time).length > 0
							? elem.nightTimeStart.match(time)[0]
							: `${elem.nightTimeStart}:00`;
					this.contract.nightTimeEnd =
						elem.nightTimeEnd.match(time) && elem.nightTimeEnd.match(time).length > 0
							? elem.nightTimeEnd.match(time)[0]
							: `${elem.nightTimeEnd}:00`;
					this.contract.minTimeBetweenDays =
						elem.minTimeBetweenDays.match(time) && elem.minTimeBetweenDays.match(time).length > 0
							? elem.minTimeBetweenDays.match(time)[0]
							: `${elem.minTimeBetweenDays}:00`;

					// fix for old values not updating
					if (!elem.useTravelDailyRate && parseFloat(elem.kmRate) > 0) {
						this.contract.useTravelDailyRate = !elem.useTravelDailyRate;
					}

					this.contract.publicHolidayCalendar = elem.publicHolidayCalendar;

					this.$v.$touch();
					// console.log({'$v': this.$v});
					if (this.$v.$invalid) {
						this.$emit('memo-deal:fields:invalid', true);
					} else {
						this.$emit('memo-deal:fields:invalid', false);
					}

					setTimeout(() => {
						this.getStandardPayCodeTitle(this.contract.defaultDayType);
					}, 250);

					this.$emit('change', elem);
				}
			},
			deep: true
		},
		startDateUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						startDate: n
					};
				}
			},
			deep: true
		},
		endDateUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						endDate: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		daysForeseenUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						daysForeseen: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		employeeStatusUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						employeeStatus: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		costCenterPrepsTypeUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						costCenterPrepsType: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		costCenterShootingTypeUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						costCenterShootingType: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		costCenterWrapTypeUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						costCenterWrapType: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		companyCostFactorUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						companyCostFactor: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		documentPackageIdUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						documentPackageId: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		isSubmitted: {
			handler(n) {
				if (!isNil(n) && n === true) {
					this.$v.$touch();
					if (this.$v.$invalid) {
						this.$emit('memo-deal:fields:invalid', true);
					} else {
						this.$emit('memo-deal:fields:invalid', false);
					}
				}
			},
			deep: true,
			immediate: true
		},
		isAutomaticSentContractUpdate: {
			handler(n) {
				if (!isNil(n)) {
					this.contract = {
						...this.contract,
						isAutomaticSentContract: n
					};
				}
			},
			deep: true
			// immediate: true
		},
		showPayCode: {
			handler(value) {
				if (!isNil(value)) {
					this.showDefaultPayCode = /*this.showStandard() && */ value;
				}
			},
			deep: true,
			immediate: true
		}
	},
	computed: {
		cnn2642Options() {
			return this.FormMenu(10006);
		},
		optionsForDefaultDayType() {
			return this.FormMenu(1010);
		},
		optionsForTimeManagementType() {
			return this.FormMenu(1117);
		},
		optionsForNumDays() {
			return this.FormMenu(1118);
		},
		isFilmSingleUserFree() {
			return store.isFilmSingleUserFree();
		},
		isFilmSingleUser() {
			return store.isFilmSingleUser();
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		},
		periodicalOptions() {
			return this.FormMenu(1333);
		},
		partialPeriodicalOptions() {
			return this.FormMenu(1332);
		},
		showOvt() {
			return store.state.myProject.useOvertime;
		},
		showOvt1() {
			return store.state.myProject.useOvt1;
		},
		showOvt2() {
			return store.state.myProject.useOvt2;
		},
		showNightHours() {
			return store.state.myProject.useNightHours;
		},
		showTooEarlyHours() {
			return store.state.myProject.useTooEarlyHours;
		},
		showTransport() {
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunch() {
			return store.state.myProject.useLunchTime;
		},
		showBeforeCallTime() {
			return store.state.myProject.useBeforeCallTime;
		},
		showWeeklyOvt() {
			return store.state.myProject.useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.state.myProject.useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.state.myProject.useWeeklyOvt2;
		},
		showSixthDayRate() {
			return store.state.myProject.useSixthDay;
		},
		showSeventhDayRate() {
			return store.state.myProject.useSeventhDay;
		},
		showSundayRate() {
			return store.state.myProject.useSundayRate;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		}
	},
	methods: {
		handleGuaranteedType(event) {
			this.contract.minimumHourGuaranteedType = event;
		},
		setActionInputDateTimePicker() {
			this.WORKTYPE = useWorktype;
			this.rangeHour = useRangeHour;
			this.rangeMinute = useRangeMinute;
		},
		handleChangeHourMin(payload) {
			this.contract[payload.fieldName + 'Str'] = payload.hourValid;
			this.convertHourMinuteToInt(payload);
		},
		convertHourMinuteToInt(payload) {
			const fieldName = payload.fieldName;
			const fieldNameStr = fieldName + 'Str';
			this.contract[fieldNameStr] = payload.hourValid;
			if (
				this.contract[fieldNameStr].split(':').length == 2 &&
				isNaN(parseInt(this.contract[fieldNameStr].split(':')[0], 10)) == false &&
				isNaN(parseInt(this.contract[fieldNameStr].split(':')[1], 10)) == false
			) {
				this.contract[fieldName] =
					parseInt(this.contract[fieldNameStr].split(':')[0], 10) * 60 + parseInt(this.contract[fieldNameStr].split(':')[1], 10);
			} else {
				this.contract[fieldName] = 0;
			}
		},
		openDateTimePickers() {
			this.rangeHour = rangeHour;
			return (this.showDateTimePickers = !this.showDateTimePickers);
		},
		computeSalaryFromEffNumHours(numHours, hourlyRate) {
			var leftToCredit = numHours;
			var salary = 0;
			if (leftToCredit > this.contract.weeklyOvtThreeLimit) {
				salary += ((hourlyRate * this.contract.weeklyOvtThreeRate) / 100) * (leftToCredit - this.contract.weeklyOvtThreeLimit);
				leftToCredit = this.contract.weeklyOvtThreeLimit;
			}
			if (leftToCredit > this.contract.weeklyOvtTwoLimit) {
				salary += ((hourlyRate * this.contract.weeklyOvtTwoRate) / 100) * (leftToCredit - this.contract.weeklyOvtTwoLimit);
				leftToCredit = this.contract.weeklyOvtTwoLimit;
			}
			if (leftToCredit > this.contract.weeklyOvtOneLimit) {
				salary += ((hourlyRate * this.contract.weeklyOvtOneRate) / 100) * (leftToCredit - this.contract.weeklyOvtOneLimit);
				leftToCredit = this.contract.weeklyOvtTwoLimit;
			}
			salary += hourlyRate * this.contract.weeklyOvtOneLimit;

			//salary = parseFloat(salary.toFixed(5));
			//console.log("salary:",salary.toFixed(10));
			return salary.toFixed(10);
		},
		computeSMG(payload) {
			//console.log("in compute smg1:",this.showCCNAnnex1());
			if (this.showCCNAnnex1()) {
				if (this.showBaseDays()) {
					this.smgBase = this.computeSalaryFromEffNumHours(this.contract.weeklyBaseEffNumHours, this.contract.hourlyRate);
				} else if (this.showFiveDays()) {
					this.contract.weeklyFiveDaysMinimumSalary = this.computeSalaryFromEffNumHours(
						this.contract.weeklyFiveDaysEffNumHours,
						this.contract.hourlyRate
					);
				} else if (this.showSixDays()) {
					this.contract.weeklySixDaysMinimumSalary = this.computeSalaryFromEffNumHours(
						this.contract.weeklySixDaysEffNumHours,
						this.contract.hourlyRate
					);
				}
			} else if (this.showCCNAnnex3()) {
				if (this.showBaseDays()) {
					this.contract.weeklyBaseMinimumSalary = this.computeSalaryFromEffNumHours(
						this.contract.weeklyBaseEffNumHours,
						this.contract.hourlyRateAnnexThree
					);
					//  console.log("in compute smg:",this.contract.hourlyRateAnnexThree);
				} else if (this.showFiveDays()) {
					this.contract.weeklyFiveDaysMinimumSalaryAnThree = this.computeSalaryFromEffNumHours(
						this.contract.weeklyFiveDaysEffNumHours,
						this.contract.hourlyRateAnnexThree
					);
				} else if (this.showSixDays()) {
					this.contract.weeklySixDaysMinimumSalaryAnThree = this.computeSalaryFromEffNumHours(
						this.contract.weeklySixDaysEffNumHours,
						this.contract.hourlyRateAnnexThree
					);
				}
			}
			// this.manageStateErrorNumber(payload, '', 'hourlyRate')
		},
		computeNumEquivBaseHours(numHours) {
			// compute number of hours at regular hourlyRate to this combination of normal, ovt1, ovt2 and ovt3 hours
			var tmp = 0.1;
			// console.log("in computeNumEquivBaseHours numHours:", numHours);
			if (numHours > this.contract.weeklyOvtThreeLimit) {
				tmp =
					this.contract.weeklyOvtOneLimit +
					((this.contract.weeklyOvtTwoLimit - this.contract.weeklyOvtOneLimit) * this.contract.weeklyOvtOneRate) / 100 +
					((this.contract.weeklyOvtThreeLimit - this.contract.weeklyOvtTwoLimit) * this.contract.weeklyOvtTwoRate) / 100 +
					((numHours - this.contract.weeklyOvtThreeLimit) * this.contract.weeklyOvtThreeRate) / 100;
			} else if (numHours > this.contract.weeklyOvtTwoLimit) {
				tmp =
					this.contract.weeklyOvtOneLimit +
					((this.contract.weeklyOvtTwoLimit - this.contract.weeklyOvtOneLimit) * this.contract.weeklyOvtOneRate) / 100 +
					((numHours - this.contract.weeklyOvtTwoLimit) * this.contract.weeklyOvtTwoRate) / 100;
			} else if (numHours > this.contract.weeklyOvtOneLimit) {
				tmp = this.contract.weeklyOvtOneLimit + ((numHours - this.contract.weeklyOvtOneLimit) * this.contract.weeklyOvtOneRate) / 100;
			} else {
				tmp = numHours;
			}
			// console.log("in computeNumEquivBaseHours:", tmp);
			return tmp;
		},
		computeHourlyRate(item) {
			// console.log("in computeHourlyRate:", item);
			var tmp = 1.0;
			if (this.showCCNAnnex1()) {
				if (this.showBaseDays()) {
					tmp = this.computeNumEquivBaseHours(this.contract.weeklyBaseEffNumHours);
				} else if (this.showFiveDays()) {
					tmp = this.computeNumEquivBaseHours(this.contract.weeklyFiveDaysEffNumHours);
				} else if (this.showSixDays()) {
					tmp = this.computeNumEquivBaseHours(this.contract.weeklySixDaysEffNumHours);
				}
				this.contract.hourlyRate = item / tmp;
				this.contract.hourlyRate = this.contract.hourlyRate.toFixed(10);
			}
		},
		showStandard() {
			return this.contract.timeManagementType === 0;
		},
		showCCNAnnex() {
			return this.contract.timeManagementType > 0 && this.contract.timeManagementType < 4;
		},
		showCCNAnnex1() {
			return this.contract.timeManagementType === 1;
		},
		showCCNAnnex3() {
			return this.contract.timeManagementType === 3;
		},
		showCCN2642CDICDD() {
			return this.contract.timeManagementType === 4;
		},
		showCCN2642CDDU() {
			return this.contract.timeManagementType === 5;
		},
		showSixDays() {
			return this.contract.workSixDays === 2;
		},
		showFiveDays() {
			return this.contract.workSixDays === 1;
		},
		showBaseDays() {
			return this.contract.workSixDays === 0;
		},
		showCNN2624DailyBase() {
			return this.contract.workSixDays === 10 || this.contract.workSixDays === 11;
		},
		showCNN2624WeeklyBase() {
			return this.contract.workSixDays === 12 || this.contract.workSixDays === 13;
		},
		showCNN2624MonthlyBase() {
			return this.contract.workSixDays === 14 || this.contract.workSixDays === 15;
		},
		validateFields() {
			//console.log("in validate fields memodeal:",this.contract);
			this.contract.dailyRate = isNaN(parseFloat(this.contract.dailyRate)) ? 0 : parseFloat(this.contract.dailyRate);
			this.contract.travelDailyRate = isNaN(parseFloat(this.contract.travelDailyRate)) ? 0 : parseFloat(this.contract.travelDailyRate);
			this.contract.hourlyRate = isNaN(parseFloat(this.contract.hourlyRate)) ? 0 : parseFloat(this.contract.hourlyRate);
			this.contract.nightTimeRate = isNaN(parseFloat(this.contract.nightTimeRate)) ? 0 : parseFloat(this.contract.nightTimeRate);
			this.contract.hourBeforeTimeRate = isNaN(parseFloat(this.contract.hourBeforeTimeRate)) ? 0 : parseFloat(this.contract.hourBeforeTimeRate);
			this.contract.kmRate = isNaN(parseFloat(this.contract.kmRate)) ? 0 : parseFloat(this.contract.kmRate);
			this.contract.lunchPerDiem = isNaN(parseFloat(this.contract.lunchPerDiem)) ? 0 : parseFloat(this.contract.lunchPerDiem);
			this.contract.hotelPerDiem = isNaN(parseFloat(this.contract.hotelPerDiem)) ? 0 : parseFloat(this.contract.hotelPerDiem);
			this.contract.dailyRightTransfer = isNaN(parseFloat(this.contract.dailyRightTransfer)) ? 0 : parseFloat(this.contract.dailyRightTransfer);
			this.contract.lunchMinimum = isNaN(parseInt(this.contract.lunchMinimum)) ? 0 : parseInt(this.contract.lunchMinimum);
			this.contract.lunchMinimumForContDay = isNaN(parseInt(this.contract.lunchMinimumForContDay)) ? 0 : parseInt(this.contract.lunchMinimumForContDay);
			this.contract.sixthDay = isNaN(parseInt(this.contract.sixthDay)) ? 0 : parseInt(this.contract.sixthDay);
			this.contract.seventhDay = isNaN(parseInt(this.contract.seventhDay)) ? 0 : parseInt(this.contract.seventhDay);
			// this.contract.weeklyOvertime = this.contract.weeklyOvertime == 1 ? true : false
			this.contract.timeManagementType = isNaN(parseInt(this.contract.timeManagementType)) ? 0 : parseInt(this.contract.timeManagementType);
			this.contract.overtimeRate = isNaN(parseFloat(this.contract.overtimeRate)) ? 0 : parseFloat(this.contract.overtimeRate);
			this.contract.weeklyFiveDaysMinimumSalary = isNaN(parseFloat(this.contract.weeklyFiveDaysMinimumSalary))
				? 0
				: parseFloat(this.contract.weeklyFiveDaysMinimumSalary);
			this.contract.weeklySixDaysMinimumSalary = isNaN(parseFloat(this.contract.weeklySixDaysMinimumSalary))
				? 0
				: parseFloat(this.contract.weeklySixDaysMinimumSalary);
			this.contract.weeklyFiveDaysEffNumHours = isNaN(parseFloat(this.contract.weeklyFiveDaysEffNumHours))
				? 0
				: parseFloat(this.contract.weeklyFiveDaysEffNumHours);
			this.contract.weeklyFiveDaysEquNumHours = isNaN(parseFloat(this.contract.weeklyFiveDaysEquNumHours))
				? 0
				: parseFloat(this.contract.weeklyFiveDaysEquNumHours);
			this.contract.weeklySixDaysEffNumHours = isNaN(parseFloat(this.contract.weeklySixDaysEffNumHours))
				? 0
				: parseFloat(this.contract.weeklySixDaysEffNumHours);
			this.contract.weeklySixDaysEquNumHours = isNaN(parseFloat(this.contract.weeklySixDaysEquNumHours))
				? 0
				: parseFloat(this.contract.weeklySixDaysEquNumHours);
			this.contract.weeklyBonusAmount = isNaN(parseFloat(this.contract.weeklyBonusAmount)) ? 0 : parseFloat(this.contract.weeklyBonusAmount);
			this.contract.weeklyFiveDaysRefSalary = isNaN(parseFloat(this.contract.weeklyFiveDaysRefSalary))
				? 0
				: parseFloat(this.contract.weeklyFiveDaysRefSalary);
			this.contract.weeklySixDaysRefSalary = isNaN(parseFloat(this.contract.weeklySixDaysRefSalary))
				? 0
				: parseFloat(this.contract.weeklySixDaysRefSalary);
			this.contract.weeklyOvtOneLimit = isNaN(parseFloat(this.contract.weeklyOvtOneLimit)) ? 0 : parseFloat(this.contract.weeklyOvtOneLimit);
			this.contract.weeklyOvtOneRate = isNaN(parseFloat(this.contract.weeklyOvtOneRate)) ? 0 : parseFloat(this.contract.weeklyOvtOneRate);
			this.contract.weeklyOvtTwoLimit = isNaN(parseFloat(this.contract.weeklyOvtTwoLimit)) ? 0 : parseFloat(this.contract.weeklyOvtTwoLimit);
			this.contract.weeklyOvtTwoRate = isNaN(parseFloat(this.contract.weeklyOvtTwoRate)) ? 0 : parseFloat(this.contract.weeklyOvtTwoRate);
			this.contract.weeklyOvtThreeLimit = isNaN(parseFloat(this.contract.weeklyOvtThreeLimit)) ? 0 : parseFloat(this.contract.weeklyOvtThreeLimit);
			this.contract.weeklyOvtThreeRate = isNaN(parseFloat(this.contract.weeklyOvtThreeRate)) ? 0 : parseFloat(this.contract.weeklyOvtThreeRate);
			this.contract.weeklyBaseEffNumHours = isNaN(parseFloat(this.contract.weeklyBaseEffNumHours)) ? 0 : parseFloat(this.contract.weeklyBaseEffNumHours);
			this.contract.hourlyRateAnnexThree = isNaN(parseFloat(this.contract.hourlyRateAnnexThree)) ? 0 : parseFloat(this.contract.hourlyRateAnnexThree);
			this.contract.weeklyBonusAmountFiveDays = isNaN(parseFloat(this.contract.weeklyBonusAmountFiveDays))
				? 0
				: parseFloat(this.contract.weeklyBonusAmountFiveDays);
			this.contract.weeklyBonusAmountSixDays = isNaN(parseFloat(this.contract.weeklyBonusAmountSixDays))
				? 0
				: parseFloat(this.contract.weeklyBonusAmountSixDays);
			this.contract.weeklyBaseRefSalary = isNaN(parseFloat(this.contract.weeklyBaseRefSalary)) ? 0 : parseFloat(this.contract.weeklyBaseRefSalary);
			this.contract.weeklyBaseMinimumSalary = isNaN(parseFloat(this.contract.weeklyBaseMinimumSalary))
				? 0
				: parseFloat(this.contract.weeklyBaseMinimumSalary);
			this.contract.weeklyFiveDaysMinimumSalaryAnThree = isNaN(parseFloat(this.contract.weeklyFiveDaysMinimumSalaryAnThree))
				? 0
				: parseFloat(this.contract.weeklyFiveDaysMinimumSalaryAnThree);
			this.contract.weeklySixDaysMinimumSalaryAnThree = isNaN(parseFloat(this.contract.weeklySixDaysMinimumSalaryAnThree))
				? 0
				: parseFloat(this.contract.weeklySixDaysMinimumSalaryAnThree);
			this.contract.transportRate = isNaN(parseFloat(this.contract.transportRate)) ? 0 : parseFloat(this.contract.transportRate);
			this.contract.ovtRate1 = isNaN(parseFloat(this.contract.ovtRate1)) ? 0 : parseFloat(this.contract.ovtRate1);
			this.contract.ovtRate2 = isNaN(parseFloat(this.contract.ovtRate2)) ? 0 : parseFloat(this.contract.ovtRate2);
			this.contract.sundayPublicHolidayFactor = isNaN(parseFloat(this.contract.sundayPublicHolidayFactor))
				? 0
				: parseFloat(this.contract.sundayPublicHolidayFactor);
			this.contract.companyCostFactor = isNaN(parseFloat(this.contract.companyCostFactor)) ? 1 : parseFloat(this.contract.companyCostFactor);
			this.contract.transportPaidAfter = isNaN(parseInt(this.contract.transportPaidAfter)) ? 0 : parseInt(this.contract.transportPaidAfter);
			this.contract.ovtLimit1 = isNaN(parseInt(this.contract.ovtLimit1)) ? 0 : parseInt(this.contract.ovtLimit1);
			this.contract.ovtLimit2 = isNaN(parseInt(this.contract.ovtLimit2)) ? 0 : parseInt(this.contract.ovtLimit2);
			this.contract.minTimeBetweenWeek = isNaN(parseInt(this.contract.minTimeBetweenWeek)) ? 0 : parseInt(this.contract.minTimeBetweenWeek);
			this.contract.beforeCallTimeRate = isNaN(parseFloat(this.contract.beforeCallTimeRate)) ? 0 : parseFloat(this.contract.beforeCallTimeRate);
			this.contract.carAllowance = isNaN(parseFloat(this.contract.carAllowance)) ? 0 : parseFloat(this.contract.carAllowance);
			this.contract.phoneAllowance = isNaN(parseFloat(this.contract.phoneAllowance)) ? 0 : parseFloat(this.contract.phoneAllowance);
			this.contract.computerAllowance = isNaN(parseFloat(this.contract.computerAllowance)) ? 0 : parseFloat(this.contract.computerAllowance);
			this.contract.boxKitAllowance = isNaN(parseFloat(this.contract.boxKitAllowance)) ? 0 : parseFloat(this.contract.boxKitAllowance);
			this.contract.productionFeeAllowance = isNaN(parseFloat(this.contract.productionFeeAllowance))
				? 0
				: parseFloat(this.contract.productionFeeAllowance);
			this.contract.dinnerPerDiem = isNaN(parseFloat(this.contract.dinnerPerDiem)) ? 0 : parseFloat(this.contract.dinnerPerDiem);
			this.contract.abroadPerDiem = isNaN(parseFloat(this.contract.abroadPerDiem)) ? 0 : parseFloat(this.contract.abroadPerDiem);
			this.contract.cdiMonthlyReferenceSalary = isNaN(parseFloat(this.contract.cdiMonthlyReferenceSalary))
				? 0
				: parseFloat(this.contract.cdiMonthlyReferenceSalary);
		},
		isEditable() {
			return !this.disableEdit;
		},
		loadEditDataToContract() {
			//console.log("loadEditDataToContract this.editData:",this.editData);
			if (!isNil(this.editData)) {
				//  console.log("editData:", this.editData);
				this.contract = {
					...this.contract,
					...this.editData
				};
			}
			this.computeSMG();
			//console.log("in loadEditDataToContract this.contract:",this.contract);
		},
		copyContract() {
			this.contractContent = this.contract;

			const omitKeys = ['costCenterPrepsType', 'costCenterShootingType', 'costCenterWrapType'];
			this.contractContent = {
				...omit(omitKeys, this.contractContent)
			};

			this.contractCopied = 1;

			this.createToastForMobile(this.FormMSG(195, 'Success'), this.FormMSG(196, 'Your deal memo was copied successfully, you can do your paste'), '');

			return;
		},
		pasteContract() {
			this.contract = this.contractContent;

			this.createToastForMobile(this.FormMSG(195, 'Success'), this.FormMSG(197, 'Paste deal memo done successfully'), '');

			return;
		},
		disablePaste() {
			return !this.contractCopied;
		},
		updateNightStart(payload) {
			this.contract.nightTimeStart = `${payload.target.value}:00`;
		},
		updateNightEnd(payload) {
			this.contract.nightTimeEnd = `${payload.target.value}:00`;
		},
		updateMinTimeBetweenDays(payload) {
			this.contract.minTimeBetweenDays = `${payload.target.value}:00`;
		},
		handleClickHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
			// console.log({data});
		},
		async handleManagementType(payload) {
			this.$emit('memo-deal:fields:clear-error', true);

			if (this.showCCN2642CDDU()) {
				if (this.contract.workSixDays === 0) {
					this.contract.workSixDays = 12;
				}
			} else {
				if (this.contract.workSixDays >= 10) {
					this.contract.workSixDays = 0;
				}
			}
			if (payload === 4) {
				const ccn2642 = await this.getCcn2642DefaultData(payload, this.contract.cdiMonthlyReferenceSalary);
				this.contract.hourlyRate = this.roundNumeric(ccn2642.hourlyRate);
				this.contract.dailyRate = this.roundNumeric(ccn2642.dailyRate);
				this.contract.monthlyRate = this.roundNumeric(ccn2642.monthlyRate);
				this.contract.totMinPerDayStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerDayStr;
				this.contract.totMinPerWeekStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerWeekStr;
			} else if (payload === 5) {
				const ccn2642 = await this.getCcn2642DefaultData(payload, this.contract.weeklyFiveDaysRefSalary, this.contract.workSixDays);
				this.setDefaultValueCcn2642(ccn2642);
			}
			if (!_.isNil(this.contract.departmentId) && !_.isNil(this.contract.functionId)) {
				this.$emit('memo-deal:department-function:required', false);
			} else {
				this.$emit('memo-deal:department-function:required', true);
			}
		},
		async handleCcn2642(payload) {
			const ccn2642 = await this.getCcn2642DefaultData(this.contract.timeManagementType, this.contract.weeklyFiveDaysRefSalary, payload);
			this.setDefaultValueCcn2642(ccn2642);
			if (!_.isNil(this.contract.departmentId) && !_.isNil(this.contract.functionId)) {
				this.$emit('memo-deal:department-function:required', false);
			} else {
				this.$emit('memo-deal:department-function:required', true);
			}
		},
		setDefaultValueCcn2642(ccn2642) {
			if (this.showCNN2624DailyBase()) {
				this.contract.hourlyRate = this.roundNumeric(ccn2642.hourlyRate);
				this.contract.dailyRate = this.roundNumeric(ccn2642.dailyRate);
				this.contract.totMinPerDayStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerDayStr;
				//
				this.contract.weeklyFiveDaysMinimumSalary = 0;
				this.contract.totMinPerWeekStr = '00:00';
				this.contract.monthlyRate = 0;
			} else if (this.showCNN2624WeeklyBase()) {
				this.contract.hourlyRate = this.roundNumeric(ccn2642.hourlyRate);
				this.contract.dailyRate = this.roundNumeric(ccn2642.dailyRate);
				this.contract.totMinPerDayStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerDayStr;
				this.contract.weeklyFiveDaysMinimumSalary = ccn2642.weeklyFiveDayMinmumSalary;
				this.contract.totMinPerWeekStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerWeekStr;
				//
				this.contract.monthlyRate = 0;
			} else if (this.showCNN2624MonthlyBase()) {
				this.contract.hourlyRate = this.roundNumeric(ccn2642.hourlyRate);
				this.contract.dailyRate = this.roundNumeric(ccn2642.dailyRate);
				this.contract.totMinPerDayStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerDayStr;
				this.contract.weeklyFiveDaysMinimumSalary = ccn2642.weeklyFiveDayMinmumSalary;
				this.contract.totMinPerWeekStr = ccn2642.totMinPerDayStr === '' ? '00:00' : ccn2642.totMinPerWeekStr;
				this.contract.monthlyRate = this.roundNumeric(ccn2642.monthlyRate);
			}
		},
		async getCcn2642DefaultData(typeManagement, refSalary, workSixDay = null) {
			return await dataCcn2642(typeManagement, refSalary, workSixDay);
		},
		async saveAsDealMemoTemplate(e) {
			this.$emit('memo-deal:save-template', e);
		},
		calculateAverageRate(rate, percent) {
			return !isNaN(rate) && !isNaN(percent) && rate > 0 && percent > 0 ? (rate * (percent / 100)).toFixed(2) : 0;
		},
		handleAccessGeneralAllowance(event, model) {
			// const oldValue = ; // @TODO : to fill with copy
			if (!event) {
				this.contract[model] = 0;
			}
		},
		getStandardPayCodeTitle(payload) {
			const options = this.FormMenu(1010);
			let menu = options.filter((item) => payload === item.value);
			menu = menu.length > 0 ? menu[0].text : '';
			menu = menu.substring(0, menu.lastIndexOf('(') - 1).trim();

			this.standardPayCodeTitle = this.FormMSG(313, 'Pay code') + ' ' + menu.toLowerCase();
			// this.showDefaultPayCode = this.showStandard() && this.showPayCode;
		},
		setPayroolCodeValue(payload, fieldName) {
			this.contract[fieldName] = +payload;
		},
		resetPayroolCodeValue(payload, fieldName) {
			if (payload) {
				this.contract[fieldName] = null;
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		handleWeeklyRefSalary() {
			new Promise((resolve, reject) => {
				setTimeout(async () => {
					resolve(await this.handleChangeOfRefSalary(this.contract.weeklyFiveDaysRefSalary));
					reject(false);
				}, 1000);
			});
		},
		handleMonthlyRefSalary() {
			new Promise((resolve, reject) => {
				setTimeout(async () => {
					resolve(await this.handleChangeOfRefSalary(this.contract.cdiMonthlyReferenceSalary));
					reject(false);
				}, 1000);
			});
		},
		async handleChangeOfRefSalary(refSalary) {
			const ccn2642 = await this.getCcn2642DefaultData(this.contract.timeManagementType, refSalary, this.contract.workSixDays);
			// console.log({ ccn2642 });
			this.contract.hourlyRate = this.roundNumeric(ccn2642.hourlyRate);
			this.contract.dailyRate = this.roundNumeric(ccn2642.dailyRate);
			this.contract.monthlyRate = this.roundNumeric(ccn2642.monthlyRate);
			this.contract.totMinPerDay = ccn2642.totMinPerDay;
			this.contract.totMinPerDayStr = ccn2642.totMinPerDayStr;
			this.contract.weeklyFiveDaysMinimumSalary = ccn2642.weeklyFiveDayMinmumSalary;
			this.contract.weeklyFiveDaysRefSalary = ccn2642.weeklyFiveDaysRefSalary;
			this.contract.totMinPerWeek = ccn2642.totMinPerWeek;
			this.contract.totMinPerWeekStr = ccn2642.totMinPerWeekStr;
			this.contract.cdiMonthlyReferenceSalary = ccn2642.cdiMonthlyReferenceSalary;
		},
		roundNumeric(value) {
			value = value.toFixed(2);
			value = value.replace(/([\.,]0{2})$/g, '');

			return value;
		},
		async getPublicHolidays() {
			const result = await getProjectPublicHolidayCalendar(true);
			this.publicHolidays = result.map((option) => ({
				value: option.calNumber,
				text: option.name
			}));
		}
	},
	validations() {
		const mandatoriesStandard = {
			contract: {
				hourlyRate: {
					required,
					decimal,
					min: greaterThanZero
				},
				dailyRate: {
					required,
					decimal,
					min: greaterThanZero
				},
				// totMinPerWeekStr: {
				//     required,
				//     hourMinuteValid
				// },
				totMinPerDayStr: {
					required,
					hourMinuteValid
				},
				totMinPerDayForContDayStr: {
					required,
					hourMinuteValid
				}
			}
		};

		const mandatoriesAnnex1 = {
			contract: {
				hourlyRate: {
					required,
					decimal,
					min: greaterThanZero
				},
				totMinPerDayStr: {
					required,
					hourMinuteValid
				}
				// totMinPerDayForContDayStr: {
				//     required,
				//     hourMinuteValid
				// },
			}
		};

		const mandatoriesAnnex3 = {
			contract: {
				totMinPerDayStr: {
					required,
					hourMinuteValid
				},
				// totMinPerDayForContDayStr: {
				//     required,
				//     hourMinuteValid
				// },
				hourlyRateAnnexThree: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};

		const mandatoriesCnn2642 = {
			contract: {
				hourlyRate: {
					required,
					decimal,
					min: greaterThanZero
				},
				dailyRate: {
					required,
					decimal,
					min: greaterThanZero
				},
				totMinPerDayStr: {
					required,
					hourMinuteValid
				}
			}
		};

		if (this.showCCNAnnex1()) {
			return mandatoriesAnnex1;
		} else if (this.showCCNAnnex3()) {
			return mandatoriesAnnex3;
		} else if (this.showCCN2642CDICDD() || this.showCCN2642CDDU()) {
			return mandatoriesCnn2642;
		}

		return mandatoriesStandard;
	}
};
</script>
<style scoped></style>
